<template>
    <div class="news w-full py-6">
        <div
            class="circle-loading-animation"
            v-if="isLoading"
        ></div>

        <div class="news-content-container mx-auto" v-if="!isLoading">
            <h1 class="text-white text-center">news</h1>

            <div v-for="(currentNews, index) in newsItems" :key="'news'+index" class="news-overview bg-greylight3 rounded-md mt-4 overflow-hidden">
                <div class="news-img" v-if="currentNews.overview_image">
                    <img :src="currentNews.overview_image" />
                </div>
                <p class="h7 text-text-greystrong">{{ currentNews.name }}</p>
                <div class="font-arial text-xxs text-text-grey mt-1">{{ currentNews.created }}</div>
                <p class="mt-2">{{currentNews.chapo}}</p>
                <router-link
                    :to="{ name : 'news-detail', params : { news_id : currentNews.slug }}"
                    class="btn btn--light-ghost mt-4"
                >
                    know-more
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name : 'news',

    data(){
        return {
            isLoading : false,

            newsItems : [
                {
                    name : 'New UKAS Website Launched Today',
                    created : '2015-10-22 14:39:08',
                    chapo : 'UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation',
                    slug : 'tutu'
                },
                {
                    name : 'New UKAS Website Launched Today',
                    created : '2015-10-22 14:39:08',
                    chapo : 'UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation',
                    slug : 'tutu'
                },
                {
                    name : 'New UKAS Website Launched Today',
                    created : '2015-10-22 14:39:08',
                    chapo : 'UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation',
                    slug : 'tutu'
                },
                {
                    name : 'New UKAS Website Launched Today',
                    created : '2015-10-22 14:39:08',
                    chapo : 'UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation',
                    slug : 'tutu'
                },
                {
                    name : 'New UKAS Website Launched Today',
                    created : '2015-10-22 14:39:08',
                    chapo : 'UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation',
                    slug : 'tutu'
                }
            ]
        }
    },

    mounted(){
        // Set the default background
        this.updateMainBackgroundState(1);

        // Show breadcrumb
        this.updateBreadcrumbState(true);
    },

    methods : {
        ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState'])
    }
}
</script>

<style lang="scss">
.news-content-container {
    width: 696px;
    margin: 0 auto;

    .news-overview {
        padding: 18px 18px 34px 18px;

        .news-text {
            margin: 9px 0 19px 0;
        }

        .news-img {
            float: right;
            margin-left: 26px;
            img {
                max-width: 206px;
                height: auto;
            }
        }
    }
}
</style>
