<template>
    <div class="dropdown relative">
        <a :class="defaultItem.classes" class="dropdown__toggle font-frutiger-regular text-md flex items-center whitespace-nowrap px-2 md:px-4" @click.prevent="opened = true">{{ defaultItem.label }}</a>
        <ul v-show="opened" :class="{'dropdown__menu--large': iconType}" class="dropdown__menu bg-white rounded">
            <li v-for="(item, index) in listItems" :key="'dropdownItem'+index" @click="$emit('link-clicked')">
                <a ref="langLink" v-if="!routerLink && item.url" :href="'/' + item.url.params.lang + ((item.url.slug) ? item.url.slug : '')" :class="item.classes" class="dropdown__menu-link">
                    {{ item.i18n ? $t(item.label) : item.label }}
                </a>
                <router-link v-if="routerLink && item.url" :to="item.url" :class="item.classes" class="dropdown__menu-link">
                    {{ item.i18n ? $t(item.label) : item.label }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name : 'dropdown',

        props : {
            defaultItem : { type : Object, default : () => []},
            listItems : { type : Array, default : () => []},
            iconType : { type : Boolean, default : false},
            routerLink : { type : Boolean, default : false }
        },

        data(){
            return {
                opened : false
            }
        },

        emits : ['link-clicked'],

        mounted(){
            // On click everywhere
            document.addEventListener('click', (pEvent) => {
                // If the element clicked isn't in dropdown or is link
                if(!this.$el.contains(pEvent.target) || pEvent.target.classList.contains('dropdown__menu-link')) {
                    // Close dropdown
                    this.opened = false;
                }
            });
        },

        methods : {
            linkClicked(){
                this.$emit('linkclicked');
            }
        }
    }
</script>

<style lang="scss">
    .dropdown {
        &__menu {
            @apply absolute text-sm;
            top: -2px;
            left: -12px;
            padding: 10px 12px;
            border: 1px solid rgba(0,0,0,0.15);
            box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
            z-index : 2;
            line-height : 18px;
            transition: all 0.2s linear;

            .btn-transparent-picto {
                @apply p-0;
                height: 24px;
            }

            a {
                @apply block font-frutiger-regular text-text-greystrong2 cursor-pointer whitespace-nowrap;
                font-size: 13px;
                padding: 0 17px;
                line-height: 24px;
                margin-bottom: 2px;

                &:hover {
                    @apply bg-none text-text-orange;
                }
            }

            &--large {
                top : -12px;
                left: 2px;
                padding: 10px 21px 11px 15px;
            }
        }

        &__toggle {
            @apply relative cursor-pointer;
            transition: all 0.2s ease-out;

            &:before {
                @apply relative;
                z-index : 3;
            }

            &:hover{
                @apply text-orange;
            }
        }
    }
</style>
