<template>
    <div ref="popin" class="popin">
        <div class="popin__dimmer"></div>
        <div class="popin__content">
            <slot></slot>
            <div v-if="closeButtonEnable" class="popin__close-button"></div>
            <h2>{{ title }}</h2>
            <div v-html="nl2br(text)"></div>
            <div class="popin__buttons">
                <div class="btn btn--orange" @click.prevent="buttonClicked(button1)">{{ button1.label }}</div>
                <div class="btn btn--ghost ml-4" @click.prevent="buttonClicked(button2)">{{ button2.label }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';

export default {
        name: 'popin',

        props: {
            closeButtonEnable: {type: Boolean, default: false},
            title: {type: String, default: ''},
            text: {type: String, default: ''},
            button1: {
                type: Object, default: () => {
                }
            },
            button2: {
                type: Object, default: () => {
                }
            },
        },

        methods: {
            nl2br(value) {
                if (!value.toString()) {
                    return '';
                }

                return value.toString().replaceAll("\n", '<br>');
            },

            buttonClicked(pButton) {
                if(pButton.action === 'close') {
                    this.closePopin();
                }
                else if(pButton.action === 'redirect') {
                    window.location.replace(pButton.url);
                }
            },

            closePopin() {
                gsap.to(this.$refs.popin, {
                    display : 'none',
                    opacity : 0,
                    duration : 0.3
                });
            }
        }
    }
</script>

<style lang="scss">
    .popin {
        @apply fixed inset-0 w-screen h-screen;

        &__content {
            @apply bg-greylight3 absolute h-auto;
            width : calc(100% - 32px);
            max-width: 450px;
            top : 50%;
            left : 50%;
            transform : translate(-50%,-50%);
            padding: 0 24px 50px 24px;
            border-radius : 6px;


            @screen md {
                @apply w-full;
            }
        }

        &__dimmer {
            @apply w-full h-full;
            background-color : rgba(0,0,0,0.4);
        }

        &__close-button {
            @apply absolute;
            top : 14px;
            right : 14px;

            //.sprite
            //.sprite-picto-croix
        }

        h2 {
            @apply text-text-greystrong m-0;
            padding: 28px 0;
        }

        p {
            @apply m-0;
        }


        &__buttons {
            @apply flex items-center;
            margin-top: 40px;

            > * + * {
                @apply relative;
                margin-right: 10px;
            }
        }
    }
</style>
