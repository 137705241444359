<template>
    <div class="w-full mt-16 mx-4 md:mx-0">
        <div class="login-dialog w-full bg-greylight3 rounded-md mx-auto pt-6 pb-12">
            <div class="text-center">
                <h2 class="text-text-greystrong">{{ $t('login.login')}}</h2>
                <p class="mt-2">
                    {{ $t('login.login-presentation') }}
                </p>
            </div>
            <form
                @submit.prevent="onSubmit"
                name="loginForm"
                role="form"
                class="form-login mt-6 mx-auto"
            >
                <input
                    type="text"
                    v-model="login.username"
                    :placeholder="$t('login.login-id')"
                    name="username"
                    required=""
                    autofocus=""
                    class="input-text input-normal mb-3"
                    :class="{'error': loginError}"
                    value=""
                >
                <input
                    type="password"
                    v-model="login.password"
                    :placeholder="$t('login.password')"
                    name="password"
                    required=""
                    class="input-text input-normal mb-3"
                    :class="{'error': loginError}"
                    value=""
                >
                <div class="login-btns flex items-center mt-4">
                    <button
                        type="submit"
                        :disabled="isLoading"
                        class="btn btn--orange mr-3 text-center"
                        :class="{
                            'is-loading': isLoading,
                            'is-valid': isLogged
                        }"
                    >
                        {{ $t('login.connection') }}
                    </button>

                    <div class="login-links">
                        <router-link :to="{ name : 'forgot-password'}" class="small-link block">{{ $t('login.forgot-password-question') }}</router-link>
                    </div>
                </div>

                <div class="loginError font-frutiger-regular text-xxs mt-3 text-purple" v-if="loginError && !showResetPasswordMessage">
                    {{ $t('login.incorrect-authentification') }}
                </div>
            </form>

            <div class="loginErrorResetPassword mt-3 font-frutiger-regular text-base text-purple" v-if="loginError && showResetPasswordMessage">
                {{ $t('login.incorrect-authentification-reset-password') }}
                <router-link
                    :to="{ name : 'forgot-password'}"
                    class="btn btn--orange"
                >{{ $t('login.reset-password') }}</router-link>
            </div>
        </div>
        <div class="create-account-link mx-auto mt-6 text-center">
            <p class="font-arial text-sm">
                {{ $t('login.not-have-account-question') }}
                <router-link :to="{ name : 'create-account'}" class="underline">{{ $t('login.create-account') }}</router-link>
            </p>
        </div>
    </div>
</template>

<script>
    import gql from 'graphql-tag';
    import { mapState, mapMutations } from 'vuex'

    export default {
        name : 'login',

        data(){
            return {
                returnTo : '',
                login : {
                    username : '',
                    password : ''
                },
                loginError : '',
                isLoading : false,
                isLogged : false,
                showResetPasswordMessage : false,
                loginForm : {}
            }
        },

        computed : {
            ...mapState(['isAuthenticated', 'flashSuccessTimeout', 'flashErrorTimeout'])
        },

        watch : {
            isAuthenticated(){
                // If we are already auth
                if(this.isAuthenticated) {
                    // Go to home
                    this.$router.push({ name : 'home', lang : this.$route.params.lang})
                }
            }
        },

        mounted(){
            // Get return to parameter it there's any
            this.returnTo = this.$route.query.returnTo;

            // if we are already authentified
            if(this.isAuthenticated) {
                // Go to doc home
                this.$router.push({ name: 'home', lang : this.$route.params.lang });
            }

            // Set the default background
            this.updateMainBackgroundState(1);

            // Show breadcrumb
            this.updateBreadcrumbState(true);

            // Update breadcrumb content
            this.updateBreadcrumb([
                {
                    url : { name : 'prehome', params : { lang : this.$i18n.locale}},
                    label : 'breadcrumb.home',
                    i18n: true
                }, {
                    url : null,
                    label : 'login.login',
                    i18n: true
                }
            ]);
        },

        methods : {
            onSubmit(){
                // Update loading state
                this.updateIsLoading(true);

                // Launch mutation
                this.$apollo.mutate({
                    mutation : gql`mutation($email : String!, $password : String!){
                        authenticate(
                            email: $email
                            password: $password
                        ) {
                            jwt
                            jwtExpiresAt
                            refreshToken
                            refreshTokenExpiresAt
                            user {
                              id
                              fullName
                            }
                          }
                    }`,
                    variables : {
                        email : this.login.username,
                        password : this.login.password
                    }
                }).then((result) => {
                    // Result
                    let user = {
                        jwt : result.data.authenticate.jwt,
                        jwtExpiresAt : result.data.authenticate.jwtExpiresAt,
                        refreshToken : result.data.authenticate.refreshToken,
                        refreshTokenExpiresAt : result.data.authenticate.refreshTokenExpiresAt,
                        item : {
                            id : result.data.authenticate.user.id,
                            fullName : result.data.authenticate.user.fullName
                        }
                    };

                    localStorage.setItem('user', JSON.stringify(user));

                    // Save user to store
                    this.updateUser(user);

                    // We are auth
                    this.updateIsAuthenticated(true);

                    // Start refreshing token
                    this.$store.dispatch('refreshToken');

                    // Flash the success message
                    this.$flashStorage.destroyAll();
                    this.flash(this.$t('flash.login-success'), 'success', { timeout : this.flashSuccessTimeout});

                    // Update loading state
                    this.updateIsLoading(false);

                    if(this.returnTo) {
                        window.location.href = this.returnTo + '?jwt=' + user.jwt;
                    } else {
                        this.$router.push({ name : 'home'});
                    }

                }).catch((error) => {
                    // Flash the error message
                    this.flash(this.$t('flash.login-error'), 'error', { timeout : this.flashErrorTimeout});

                    // Update loading state
                    this.updateIsLoading(false);

                    // Error
                    console.error('Login error : ', error);
                });
            },

            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb', 'updateUser', 'updateIsAuthenticated', 'updateIsLoading'])
        }
    }
</script>

<style lang="scss">
.login-dialog {
    max-width: 451px;

    .form-login{
        width: 240px;
    }

    .login-btns {

        .login-links {
            width: 120px;
        }
    }
}

//.loginErrorResetPassword {
//    padding: 0 45px;
//
//    .btn {
//        text-align: center;
//        margin-top: 25px;
//    }
//}

//.loading-btn {
//    //.inlineblock;
//    vertical-align: top;
//    margin-right: 10px;
//    width: 105px;
//    position: relative;
//    img {
//        margin-left: 24px;
//    }
//    &:hover {
//        //background-color: @bd-bs-aplats-orange;
//    }
//}
</style>
