<template>
    <div v-show="isLoading" class="loader">
        <div class="loader__content">
            <img class="loader__img" src="~@/assets/images/common/loader.gif" />
            <h5 class="loader__message">
                {{ $t('common.loading-in-progress') }}&hellip;
            </h5>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name : 'loader',

        computed : {
            ...mapState(['isLoading'])
        }
    }
</script>

<style lang="scss">
    .loader {
        @apply fixed bg-greylight3 overflow-hidden;
        top : 50%;
        left : 50%;
        transform : translate(-50%, -50%);
        border-radius: 6px;
        height: 162px;
        width: 230px;
        box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.5);

        &__content {
            @apply h-full flex flex-col justify-center items-center;
            transform-origin: 50% 50%;
            transform-style: preserve-3d;
        }

        &__message {
            @apply mt-4;
            width: 100%;
            text-align: center;
            font-size: 18px;
            line-height: 25px;
        }
    }
</style>
