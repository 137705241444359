<template>
    <div class="header-container relative bg-greylight3">
        <div class="h-full flex justify-between items-center px-4 md:px-8">
            <div class="mr-4 md:mr-0">
                <router-link
                    v-if="$route.name != 'prehome' && $route.name != 'home'"
                   :to="{ name : 'prehome', params : { lang : $route.params.lang}}"
                   :aria-label="$t('header.logo-aria-label')"
                >
                    <img src="~@/assets/images/logo/logo-accrediworld.png" :alt="$t('header.logo-alt')" class="hidden md:block" />
                    <img src="~@/assets/images/logo/logo-accrediworld-mobile.png" :alt="$t('header.logo-alt')" class="md:hidden"/>
                </router-link>
                <template v-else>
                    <img src="~@/assets/images/logo/logo-accrediworld.png" :alt="$t('header.logo-alt')" class="hidden md:block"/>
                    <img src="~@/assets/images/logo/logo-accrediworld-mobile.png" :alt="$t('header.logo-alt')" class="md:hidden"/>
                </template>
            </div>

            <div class="flex items-center">
                <div v-if="isAuthenticated" class="header-mobile-search relative search-icon md:hidden" @click="toggleSearchbar"></div>

                <div v-if="isAuthenticated" ref="searchbar" class="header-search-container relative fade-appear md:mr-2">
                    <form
                        @submit.prevent="$router.push({ name : 'search', query : { q : $refs.search.value },  params : { lang : $route.params.lang}})"
                    >
                        <input
                            ref="search"
                            type="text"
                            name="search"
                            class="input-search"
                            :placeholder="$t('header.search-placeholder')"
                        />
                        <span class="search-icon"></span>
                    </form>
                </div>


                <div v-if="!isAuthenticated && !($route.name === 'prehome' && $i18n.locale === 'fr-fr')" class="connect-menu">
                    <div class="bd-ds-connect mr-2 md:mr-4">
                        <router-link :to="{ name : 'login', params : { lang : $i18n.locale}}" class="btn btn--orange btn-connect-picto whitespace-nowrap">{{ $t('header.login-button') }}</router-link>
                    </div>
                </div>

                <dropdown
                    v-if="isAuthenticated && myprofile"
                    :default-item="{
                        label: $t('header.profile-dropdown'),
                        classes: 'btn-transparent-picto',
                    }"
                    :list-items="myprofile"
                    :icon-type="true"
                    :router-link="true"
                    class="profil-dropdown"
                ></dropdown>

                <dropdown
                    v-if="locales && currentLanguage"
                    :default-item="{
                        label: currentLanguage,
                        classes: '',
                    }"
                    :list-items="locales"
                    :icon-type="false"
                    :router-link="false"
                    class="switch-language"
                    role="navigation"
                    :aria-label="$t('aria.label.locale-picker-nav')"
                    v-on:link-clicked="updateCurrentLanguage"
                ></dropdown>

                <img src="~@/assets/images/logo/logo-bd.svg" :alt="$t('header.secondary-logo-alt')" class="flex-shrink-0 hidden md:block" height="40" width="65">
            </div>
        </div>
    </div>

</template>

<script>
    import { gsap } from 'gsap';
    import { mapState } from 'vuex';
    import Dropdown from '@/app/components/Dropdown';

    export default {
        name: 'header-custom',

        components: {Dropdown},

        data() {
            return {
                currentLanguage : null,
                searchbarOut : false,

                myprofile: [
                    {
                        url: { name : 'user-subscriptions', slug : '', params : { lang : this.$i18n.locale}},
                        label: 'header.profile-dropdown',
                        i18n: true
                    },
                    {
                        url: { name : 'user-subscriptions', slug : '/user/subscriptions', params : { lang : this.$i18n.locale}},
                        label: 'header.manage-subscriptions',
                        i18n: true
                    },
                    {
                        url: { name : 'user-password', slug : '/user/password', params : { lang : this.$i18n.locale}},
                        label: 'header.manage-password',
                        i18n: true
                    },
                    {
                        url: { name : 'user-profile', slug : '/user/profile', params : { lang : this.$i18n.locale}},
                        label: 'header.manage-profile',
                        i18n: true
                    },
                    {
                        url : { name : 'logout', slug : '/logout', params : { lang : this.$i18n.locale}},
                        label : 'header.logout',
                        i18n: true
                    }
                ]
            };
        },

        computed: {
            ...mapState(['isAuthenticated', 'langs']),

            locales () {
                if (this.langs.length < 1) {
                    return null;
                }

                return this.langs.map(item => ({
                    url: {name: this.$route.name, params: {lang: item.slug}},
                    label: item.label
                }));
            },
        },

        watch : {
            $route(to, from) {
                // On route change, if we aren't on search page
                if(to.name !== 'search' && this.$refs.search) {
                    // Clear the search input
                    this.$refs.search.value = '';
                }
            },

            langs(){
                // Update the current language
                this.updateCurrentLanguage()
            }
        },

        methods : {
            updateCurrentLanguage(){
                if(this.langs.length > 0) {
                    this.currentLanguage =  this.langs.find((o) => {
                        return o.slug === this.$i18n.locale
                    }).label
                }
            },

            toggleSearchbar(){
                gsap.to(this.$refs.searchbar, {
                    y : (this.searchbarOut) ? '-100%' : 0,
                    display : (this.searchbarOut) ? 'none' : 'block',
                    opacity : (this.searchbarOut) ? 0 : 1,
                    duration : 0.3,
                    ease : 'power3.out',
                    onComplete : () => {
                        this.searchbarOut = !this.searchbarOut;

                        if(this.searchbarOut) {
                            this.$refs.searchbar.querySelector('input').focus();
                        }
                    }
                });
            },
        },
    };
</script>
