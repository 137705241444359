<template>
    <div class="search w-full mx-4 md:mx-0 pb-6">
        <div class="search-content-container">
            <div v-if="isLoading" class="circle-loading-animation"></div>

            <div v-if="!isLoading">
                <h2>{{$t('search.results-for-search')}} "{{ searchTerm }}"</h2>

                <div class="document-select-bar">
                    <div class="left"></div>
                    <div class="right">
                    <span class="selectionButton">
                        <a @click.prevent="selectAll" class="selectAllButon flex items-center md:ml-4">
                            <div class="btn-nav btn-check"></div>
                            <div class="btn-label">{{ $t('documents.filters.select-all') }}</div>
                        </a>
                        <a @click.prevent="unselectAll" class="deselectAllButton flex items-center md:ml-4">
                            <div class="btn-nav btn-croix"></div>
                            <div class="btn-label">{{ $t('documents.filters.deselect-all') }}</div>
                        </a>
                    </span>
                        <a
                            v-if="documentSelection.length > 0"
                            :href="multipleDownloadUrl"
                            class="downloadSelectionButton flex items-center md:ml-4"
                        >
                            <div class="btn-nav btn-dl"></div>
                            <div class="btn-label">{{ $t('documents.filters.download-selection') }}</div>
                        </a>
                    </div>
                </div>

                <div v-if="search && pages && documents" class="search-category-container" >
                    <!-- Pages -->
                    <div class="search-category category-pages" >
                        <div class="search-category-header">
                            <p v-if="pages.length <= 1">

                                {{pages.length}} {{$t('search.pages-found-singular')}}
                            </p>
                            <p v-if="pages.length > 1">
                                {{pages.length}} {{$t('search.pages-found-plural')}}
                            </p>
                        </div>
                        <div
                            v-for="(result, index) in pages"
                            :key="'pageresult'+index"
                            :class="{'last': index === pages.length - 1}"
                        >
                            <router-link :to="{ name : getRouteName(result), params : { lang : $route.params.lang, slug : result.slug }}" class="inline-link block w-full">
                                <span class="search-line block">
                                    <span v-html="result.title" class="font-arial font-bold text-greystrong "></span>
                                </span>
                            </router-link>
                        </div>
                    </div>

                    <!-- Documents -->
                    <div class="search-category category-documents" >
                        <div class="search-category-header">
                            <p v-if="documents.length <= 1">
                                {{documents.length}} {{$t('search.documents-found-singular')}}
                            </p>
                            <p v-if="documents.length > 1">
                                {{documents.length}} {{$t('search.documents-found-plural')}}
                            </p>
                        </div>
                        <div
                            v-for="(result, index) in documents"
                            :key="'docresult'+index"
                            :class="{'last': index === documents.length -1 }"
                        >
                            <div class="search-line flex items-center">
                                <div class="w-10 mr-4 flex-shrink-0">
                                    <checkbox
                                        v-if="result.__typename === 'documents_file_Entry' && result.id"
                                        class="bd-checkbox doc-checkbox"
                                        label=""
                                        :check-style="'check'"
                                        :selected="documentSelection.indexOf(result.id) !== -1"
                                        @check-toggled="(pState) => {documentToggled(pState, result.id)}"
                                    ></checkbox>
                                </div>
                                <div v-if="result.image" class="document-result-image">
                                    <img
                                        v-if="result.image"
                                        :src="result.image"
                                        width="16"
                                        height="16"
                                    >
                                </div>
                                <div class="">
                                    <p class="font-arial font-bold text-greystrong leading-normal">{{ result.title }}</p>
                                    <p v-if="result.documentsType.length > 0" class="font-arial font-bold text-xxxs text-brownstrong2 uppercase leading-normal">{{ result.documentsType[0].title }}</p>

                                    <div v-if="result.id" class="flex justify-start items-center mt-2">
                                        <a
                                            v-if="result.id"
                                            :href="`/${localeSlug}/documents/${result.id}/view?jwt=${user.jwt}`"
                                            target="_blank"
                                            class="btn-small-ghost"
                                        >
                                            {{ $t('documents.consult') }}
                                        </a>
                                        <a
                                            v-if="result.__typename === 'documents_file_Entry' && result.id"
                                            :href="`/${localeSlug}/documents/${result.id}/download?jwt=${user.jwt}`"
                                            class="btn-small-ghost ml-4"
                                            target="_blank"
                                            :title="result.title"
                                            download
                                        >
                                            {{ $t('documents.download') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapGetters} from 'vuex';
    import gql from "graphql-tag";
    import Checkbox from '../components/Checkbox';

    const RESULT_TYPE_CONTENT_PAGE = 'contentPages_contentPages_Entry';
    const RESULT_TYPE_DOCUMENT_CATEGORY = 'documentCategories_documentCategories_Entry';

    export default {
        name : 'search-page',

        components: { Checkbox },

        data(){
            return {
                searchTerm : '',
                pages : [],
                documents : [],
                documentSelection: [],

                isLoading : false
            }
        },

        computed : {
            multipleDownloadUrl() {
                let docPart = '';
                this.documentSelection.forEach((pId) => {
                    docPart += '&documentIds[]='+ pId
                });
                return '/'+ this.$route.params.lang + '/documents/download-archive?jwt='+ this.user.jwt + docPart
            },

            ...mapState(['user']),
            ...mapGetters(['localeSlug'])
        },

        watch : {
            $route (to, from){
                // Get the search term
                this.searchTerm = this.$route.query.q;
            }
        },

        mounted(){
            // Get the search term
            this.searchTerm = this.$route.query.q;

            // Update loading state
            this.updateIsLoading(true);

            // Set the default background
            this.updateMainBackgroundState(2);

            // Show breadcrumb
            this.updateBreadcrumbState(true);

            // Update breadcrumb content
            this.updateBreadcrumb([
                {
                    url : { name : 'home', params : { lang : this.$i18n.locale}},
                    label : 'breadcrumb.home',
                    i18n: true
                }, {
                    url : null,
                    label : 'search.search-page',
                    i18n: true
                }
            ]);
        },

        methods : {
            documentToggled(pState, pId) {
                if(pState) {
                    this.documentSelection.push(pId);
                } else {
                    this.documentSelection.splice(this.documentSelection.indexOf(pId), 1);
                }
            },

            // Select all documents
            selectAll() {
                // Go through all types
                this.documents.forEach((pDocument) => {
                    // Go through all doc in the type
                    if(pDocument.documentsFile && pDocument.documentsFile.length > 0) {
                        // Add the id to the selection
                        this.documentSelection.push(pDocument.id);
                    }
                });
            },

            // Unselect all documents
            unselectAll(){
                // Empty selection array
                this.documentSelection = [];
            },

            getRouteName(result) {
                if (result.__typename === RESULT_TYPE_CONTENT_PAGE) {
                    return 'content-page';
                }

                return 'category';
            },

            ...mapMutations(['updateIsLoading', 'updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb']),
        },

        apollo : {
            search : {
                query : gql`
                    query Search($term : String!, $site : String!) {
                      search : search(term : $term, siteHandle : $site) {
                        id
                        title
                        slug
                        __typename
                        ... on documents_file_Entry {
                          id
                          documentsType {
                            title
                          }
                          documentsFile {
                            id
                          }
                        }
                        ... on documents_cta_Entry {
                          id
                          documentsType {
                            title
                          }
                        }
                      }
                    }
                `,

                variables(){
                    return {
                        term : this.$route.query.q,
                        site : this.$route.params.lang.split('-')[0] + this.$route.params.lang.split('-')[1].toUpperCase()
                    }
                },

                result(pResult){
                    // Update loading state
                    this.updateIsLoading(false);

                    // Get the pages
                    this.pages = pResult.data.search.filter(o => {
                        return [ RESULT_TYPE_CONTENT_PAGE, RESULT_TYPE_DOCUMENT_CATEGORY, ].includes(o.__typename);
                    });

                    // Get the documents
                    this.documents = pResult.data.search.filter(o => {
                        return ![ RESULT_TYPE_CONTENT_PAGE, RESULT_TYPE_DOCUMENT_CATEGORY ].includes(o.__typename);
                    });
                }
            }
        }
    }
</script>
