import Vue from 'vue';
import Vuex from 'vuex';
import gql from "graphql-tag";
import apolloProvider from "../apollo";
import subscriptions from '@/app/store/modules/subscriptions';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        subscriptions: subscriptions,
    },

    state: {
        mainBackgroundState : 0,
        breadcrumb : [],
        breadcrumbState : false,
        user : {},
        isAuthenticated : false,

        langs : [],
        countries : [],

        documentSelection : [],

        isLoading : false,

        flashSuccessTimeout : 5000,
        flashErrorTimeout : 8000,
    },

    getters: {
        localeSlug() {
            return document.documentElement.lang.toLowerCase();
        },

        mainBackgroundState(state) {
            return state.mainBackgroundState;
        },

        breadcrumb(state) {
            return state.breadcrumb;
        },

        breadcrumbState(state) {
            return state.breadcrumbState;
        },

        user(state) {
            return state.user;
        },

        isAuthenticated(state) {
            return state.isAuthenticated;
        },

        langs(state) {
            return state.langs;
        },

        countries(state) {
            return state.countries;
        },

        flashSuccessTimeout(state) {
            return state.flashSuccessTimeout;
        },

        flashErrorTimeout(state) {
            return state.flashErrorTimeout;
        },

        documentSelection(state) {
            return state.documentSelection;
        },

        isLoading(state) {
            return state.isLoading;
        },
    },

    mutations: {
        updateMainBackgroundState(state, value){
            state.mainBackgroundState = value;
        },

        updateBreadcrumb(state, value){
            state.breadcrumb = [];
            state.breadcrumb = value;
        },

        updateBreadcrumbState(state, value){
            state.breadcrumbState = value;
        },

        updateUser(state, value){
            state.user = value;
        },

        updateIsAuthenticated(state, value) {
            state.isAuthenticated = value;
        },

        updateLangs(state, value) {
            state.langs = value;
        },

        updateCountries(state, value) {
            state.countries = value;
        },

        updateDocumentSelection(state, value) {
            state.documentSelection = value;
        },

        pushDocumentSelection(state, value) {
            state.documentSelection.push(value);
        },

        removeFromDocumentSelection(state, value) {
            state.documentSelection.splice(state.documentSelection.indexOf(value), 1);
        },

        updateIsLoading(state, value) {
            state.isLoading = value;
        },
    },

    actions : {
        refreshToken(){
            // Renew the expires at
            setInterval(() => {
                let user = JSON.parse(localStorage.getItem('user'));

                console.log('trace : interval for refresh', user, user.expiresAt);

                apolloProvider.defaultClient.mutate({
                    mutation : gql`mutation RefreshToken($refreshToken : String) {
                        refreshToken(refreshToken: $refreshToken) {
                            jwt
                            jwtExpiresAt
                            refreshToken
                            refreshTokenExpiresAt
                            user {
                              id
                              fullName
                            }
                        }
                    }`,
                    variables : {
                        refreshToken : user.refreshToken,
                    }
                }).then((result) => {
                    // Create clean object to save
                    const user = {
                        jwt : result.data.refreshToken.jwt,
                        jwtExpiresAt : result.data.refreshToken.jwtExpiresAt,
                        refreshToken : result.data.refreshToken.refreshToken,
                        refreshTokenExpiresAt : result.data.refreshToken.refreshTokenExpiresAt,
                        item : {
                            id : result.data.refreshToken.user.id,
                            fullName : result.data.refreshToken.user.fullName
                        }
                    };

                    console.log('trace : new token : ', user, user.jwtExpiresAt);
                    // Store in session
                    localStorage.setItem('user', JSON.stringify(user));

                    // Save user to store
                    this.commit('updateUser', user);

                    this.commit('updateIsAuthenticated', true);
                });
            }, 600000); //600000
        },


        async writeSchema({commit}, {app}) {

            // only update fragements locally
            // if (process.env.NODE_ENV == 'local') {

                // LOAD FRAGMENT TYPES AND STORE IN FILE
                // APOLLO READS THIS FILE LATER
                fetch(process.env.APOLLO_ENGINE_API_ENDPOINT, {
                    method: 'POST',
                    body: JSON.stringify({
                        variables: {},
                        query: `
                            {
                                __schema {
                                    types {
                                        kind
                                        name
                                        possibleTypes {
                                            name
                                        }
                                    }
                                }
                            }
                        `,
                    }),
                })
                    .then(result => result.json())
                    .then(result => {

                        // here we're filtering out any type information unrelated to unions or interfaces
                        const filteredData = result.data.__schema.types.filter(
                            type => type.possibleTypes !== null,
                        );
                        result.data.__schema.types = filteredData;
                        fs.writeFile('./apollo/schema.json', JSON.stringify(result.data), err => {
                            if (err) {
                                console.error('Error writing fragmentTypes file', err);
                            }
                        });
                    });
            // }
        },
    },
})
