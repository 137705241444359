<template>
    <div class="main-background absolute inset-0">
        {{ mainBackgroundState }}
        <img
            ref="mainbackgrounds"
            v-for="(image, index) in backgrounds"
            :key="'mainbackgroundimage'+index"
            :src="getImage(image.url)"
            :class="{'opacity-0' :  index !== mainBackgroundState}"
            class="main-background__image absolute inset-0 w-full h-full object-cover"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { gsap } from 'gsap';

    const imageContext = require.context('@/assets/images/common/');

    export default {
        name : 'main-background',

        data(){
            return {
                backgrounds : [
                    {
                        url : 'main-background-image.jpg'
                    },
                    {
                        url : 'main-background-image-blur-1.jpg'
                    },
                    {
                        url : 'main-background-image-blur-2.jpg'
                    },
                ]
            }
        },

        computed : {
            ...mapState(['mainBackgroundState'])
        },

        methods : {
            getImage(pUrl) {
                return imageContext(`./${pUrl}`)
            },
        }
    }
</script>
