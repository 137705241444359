<template>
    <div class="document-card flex flex-col justify-between">
        <div class="">
            <div v-if="card.documentsImage && card.documentsImage.length > 0" class="doc-image overflow-hidden mb-4">
                <img
                    :src="card.documentsImage[0].url"
                    width="283"
                    height="200"
                />
            </div>

            <p :title="card.title" class="h7">{{ card.title }}</p>

            <div v-if="!showImage" class="doc-info">
                <div v-if="card.isNew && !docViewed" class="doc-state new">{{ $t('documents.newDoc') }}</div>
                <div class="doc-update">
                    <template v-if="card.dateUpdated">
                        <span>{{ $t('documents.update-on') }}</span>
                        <span>{{ card.dateUpdated.split('T')[0] }}</span>
                    </template>
                </div>
                <div v-if="card.version" class="doc-version">
                    {{ $t('documents.version') }} {{ card.version }}
                </div>
            </div>

            <div
                v-if="card.documentsDescription"
                class="doc-description body2"
                v-html="card.documentsDescription"
            ></div>
        </div>

        <div class="mt-6">
            <div class="doc-checkbox-container flex items-center">
                <checkbox
                    v-if="!card.documentsCta"
                    class="bd-checkbox doc-checkbox"
                    :label="$t('documents.select')"
                    :check-style="'check'"
                    :selected="(documentSelection.indexOf(card.id) !== -1) ? 1 : 0"
                    @check-toggled="documentToggled"
                ></checkbox>

                <checkbox
                    class="bd-subscribe-doc doc-checkbox"
                    :label="$t('documents.subscribe')"
                    :check-style="'star'"
                    :selected="isSub"
                    @check-toggled="toggleSubscription"
                ></checkbox>
            </div>
            <div v-if="card.id" class="doc-btn">
                <a v-if="!card.documentsCta" :href="`${card.downloadUrl}?jwt=${user.jwt}`" target="_blank" class="btn btn--grey" download @click="onDocumentLinkClicked">
                    {{ $t('documents.download') }}
                </a>
                <a :href="`${card.viewUrl}?jwt=${user.jwt}`" target="_blank" class="btn btn--light-ghost" @click="onDocumentLinkClicked">
                    {{ $t('documents.consult') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';
    import Checkbox                             from "./Checkbox";

    export default {
        name: 'document-card',

        components: {Checkbox},

        props: {
            card: { type: Object, default: () => {}},
            showImage: {type: Boolean, default: true},
        },

        data() {
            return {
                docViewed: false
            }
        },

        computed: {
            ...mapState(['documentSelection', 'user', 'breadcrumb']),
            ...mapGetters(['localeSlug']),
            ...mapGetters('subscriptions', ['isSubscribed']),

            subscriptionDescriptor() {
                return {
                    type: 'document',
                    documentId: parseInt(this.card.id),
                };
            },

            isSub(){
                const isDocSub = (this.isSubscribed(this.subscriptionDescriptor));
                const isParentSub = this.breadcrumb.find((pItem) => pItem.isSubscribed);

                return (isParentSub) ? 0.5 : (isDocSub) ? 1 : 0;
            },
        },

        methods: {
            ...mapMutations(['pushDocumentSelection', 'removeFromDocumentSelection']),
            ...mapMutations('subscriptions', ['addSubscription', 'removeSubscription']),
            ...mapActions('subscriptions', ['saveSubscriptions', 'fetchSubscriptions']),

            documentToggled(pState) {
                if(pState) {
                    this.pushDocumentSelection(this.card.id);
                } else {
                    this.removeFromDocumentSelection(this.card.id);
                }
            },

            toggleSubscription(value){
                if (value) {
                    this.addSubscription(this.subscriptionDescriptor);
                } else {
                    this.removeSubscription(this.subscriptionDescriptor)
                }

                this.saveSubscriptions();
            },

            onDocumentLinkClicked() {
                this.docViewed = true;

                if (!this.isSubscribed(this.subscriptionDescriptor)) {
                    this.fetchSubscriptions();
                }
            },
        }
    }
</script>

<style lang="scss">
.document-card {
    @apply bg-greylight3 overflow-hidden rounded-md;
    min-height: 290px;
    width: 283px;
    padding: 20px 18px 24px 18px;

    abbr[title] {
        border: none;
        cursor: default;
    }

    ul {
        @apply m-0;
    }

    &.show-image {
        .title {
            margin-top: 20px;
        }
    }

    .doc-image {
        @apply relative;
        margin-top: -20px;
        margin-left: -18px;
        width: 283px;

        &:after {
            @apply absolute inset-0;
            content: '';
            background: url('~@/assets/images/common/degrade-images-foreground.png') repeat-x bottom;
        }
    }

    .title {
        @apply text-text-greystrong overflow-hidden;
    }

    .doc-info {
        @apply font-arial text-xxs text-text-grey overflow-hidden;
        margin-top: 9px;

        .doc-update {
            img {
                margin-right: 6px;
            }
        }

        .doc-state {
            @apply text-white;
            float: right;
            height: 16px;
            line-height: 16px;
            padding: 0 4px;
            margin-top: -1px;

            &.new {
                @apply bg-orange;
            }

            &.downloaded {
                @apply bg-green;
            }
        }
    }

    .doc-description {
        @apply block overflow-hidden;
        margin-top: 11px;

        p {
            margin: 0;
        }
    }

    .doc-checkbox-container {
        margin-top: 20px;

        .checkbox {
            @apply mr-3;
        }
    }

    .doc-btn {
        margin-top: 16px;

        .btn {
            @apply mr-2;
        }
    }

}
</style>
