<template>
    <footer class="footer relative px-8 py-2 md:py-0" v-if="footer">
        <ul class="flex flex-col md:flex-row items-center text-xxs" role="navigation" :aria-label="$t('aria.label.footer-nav')">
            <li v-for="(link, key) in footer.footerMenu" :key="key">
                <a :href="link.cta.url"
                   :target="link.cta.target"
                   :aria-label="link.cta.ariaLabel"
                   class="small-link small-link--greystrong"
                   v-text="link.cta.text"
                ></a>
            </li>
            <li v-if="lastModification" class="small-link small-link--greystrong">
                {{ $t('footer.last-update', { date:  lastModification}) }}
            </li>
        </ul>
    </footer>

</template>

<script>
import gql from 'graphql-tag';

export default {
    name: 'footer-custom',

    computed: {
        lastModification() {
            if(this.lastUpdate) {
                return new Intl.DateTimeFormat(this.$i18n.locale, { day: 'numeric', month: 'numeric', 'year': 'numeric' }).format(new Date(this.lastUpdate))
            }

            return null;
        }
    },

    apollo: {
        // Simple query that will update the 'hello' vue property
        footer: gql`
          query {
            footer: globalSet(handle: "footer") {
              ... on footer_GlobalSet {
                footerMenu: globalSetsFooterMenu {
                  ... on globalSetsFooterMenu_cta_BlockType {
                    cta {
                      url
                      text
                      target
                      ariaLabel
                    }
                  }
                }
              }
            }
          }
        `,

        lastUpdate: gql`
            query {
              lastUpdate
            }
        `

    },
};
</script>
