<template>
    <div class="nav-bar mx-auto flex justify-between items-center">
        <router-link :to="{ name : 'news'}" class="flex items-center">
            <div class="btn-nav btn-news mr-2"></div>
            <div class="btn-label">all-news</div>
        </router-link>

        <div class="flex items-center">
            <span>
                <router-link :to="{ name : 'news-detail' , params : { news_id : 'tutu'}}" class="flex items-center">
                    <div class="btn-label">previous</div>
                    <div class="btn-nav btn-left ml-2"></div>
                </router-link>
            </span>
                <span>
                <router-link :to="{ name : 'news-detail' , params : { news_id : 'tutu'}}" class="flex items-center">
                    <div class="btn-nav btn-right mx-2"></div>
                    <div class="btn-label">next</div>
                </router-link>
            </span>
        </div>
    </div>

</template>

<script>
    export default {
        name : 'news-navigation',

        props : {
            previousNews : { type : Object, default : () => {}},
            nextNews : { type : Object, default : () => {}},
        }
    }
</script>

<style lang="scss">
    .nav-bar {
        width: 696px;

        a {
            @apply font-frutiger-regular text-md text-text-greystrong;

            &:hover {
                @apply no-underline;
            }
        }
    }
</style>
