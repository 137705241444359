<template>
    <section class="document-category w-full">
        <div class="document-category__content">
            <!-- IF CATEGORY -->
            <div v-if="children && children.length > 0" class="flex justify-center lg:justify-start flex-wrap">
                <router-link
                    :to="routeBack"
                    class="category-card item back-card card"
                >
                    <div class="btn-rond">
                        <img src="~@/assets/images/common/back-button.png" />
                    </div>
                    <h5 class="back-title h5">{{ $t('documents.back') }}</h5>
                </router-link>
                <category-card
                    v-for="(item, index) in children"
                    :key="'card'+index"
                    :card="item.category"
                    :unread-document-count="item.unreadDocumentCount"
                    type="category"
                    class=""
                >
                </category-card>
                <category-card
                    v-for="(item, index) in types"
                    :key="'card'+index"
                    :card="item.type"
                    :unread-document-count="item.unreadDocumentCount"
                    type="type"
                    :category-slug="$route.params.slug"
                    class=""
                >
                </category-card>
            </div>

            <template
                v-if="category && types && types.length > 0 && children && children.length === 0"
            >
                <div class="category-description-container flex flex-row justify-start md:justify-between">
                    <router-link
                        :to="routeBack"
                        class="back-button-container md:w-full"
                    >
                        <div class="btn-rond" >
                            <img src="~@/assets/images/common/back-button.png" />
                        </div>
                        <h5 class="back-title h5">{{ $t('documents.back') }}</h5>
                    </router-link>
                    <div class="description-container">
                        <h1 class="title">{{ category.title }}</h1>
                    </div>
                    <div v-if="category.documentCategoriesImage.length > 0" class="image-container" :class="{ 'image-container--card-header-cover': category.documentShowImageAsCardHeaderCover }">
                        <img :src="category.documentCategoriesImage[0].url" />
                    </div>
                </div>

                <div class="flex justify-center flex-wrap">
                    <category-card
                        v-for="(item, index) in types"
                        :key="'card'+index"
                        :card="item.type"
                        :unread-document-count="item.unreadDocumentCount"
                        type="type"
                        :category-slug="$route.params.slug"
                        class=""
                    >
                    </category-card>
                </div>
            </template>
        </div>
        <transition name="error">
            <div v-show="!isLoading && childrenRequestDone && typesRequestDone && children && types && children.length === 0 && types.length === 0" class="flex flex-col items-center justify-center h-full -mt-10">
                <h2 class="text-center">{{ $t('documents.no-content')}}</h2>
                <router-link :to="{ name : 'home'}" class="btn btn--orange mt-4">{{ $t('common.return-to-home') }}</router-link>
            </div>
        </transition>
    </section>
</template>

<script>
    import gql from "graphql-tag";
    import { mapState, mapMutations } from 'vuex';
    import CategoryCard from "../../components/CategoryCard";

    export default {
        name : 'document-category',

        components : { CategoryCard },

        data() {
            return {
                category: null,
                children: null,
                types: null,
                typesRequestDone: false,
                childrenRequestDone: false,
                timeoutDone: false
            };
        },

        computed : {
            routeBack(){
                return (this.breadcrumb) ? this.breadcrumb[this.breadcrumb.length -2].url : {};
            },

            ...mapState(['breadcrumb', 'isLoading']),
        },

        watch : {
            category(){
                // Update the breadcrumb
                this.updateBreadcrumb([]);

                this.breadcrumbArray = [
                    {
                        url : { name : 'home', params : { lang : this.$i18n.locale}},
                        label : 'breadcrumb.home',
                        i18n: true
                    }
                ];


                // If the request isn't empty
                if(this.category && this.category.ancestors.length > 0) {
                    // Go through ancestors
                    this.category.ancestors.forEach((pAncestor) => {
                        let breaditem = {
                            url : { name : 'category', params : { lang : this.$i18n.locale, slug : pAncestor.slug}},
                            label : pAncestor.title
                        };

                        if(this.breadcrumbArray.indexOf(breaditem) === -1) {
                            // Add to the breadcrumb array
                            this.breadcrumbArray.push({
                                url : { name : 'category', params : { lang : this.$i18n.locale, slug : pAncestor.slug}},
                                label : pAncestor.title
                            });
                        }
                    });
                }

                // Breadcrumb object for this page
                let thispage = {
                    url : null,
                    label : this.category.title
                };

                // If it isn't already in the breadcrumb
                if(this.breadcrumbArray.indexOf(thispage) === -1) {
                    // add it
                    this.breadcrumbArray.push(thispage);
                }

                // Update the breadcrumb
                this.updateBreadcrumb(this.breadcrumbArray);
            }
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(2);

            // Show breadcrumb
            this.updateBreadcrumbState(true);

            // Update loading state
            this.updateIsLoading(true);
        },

        methods : {
              ...mapMutations(['updateMainBackgroundState' , 'updateBreadcrumb', 'updateBreadcrumbState', 'updateIsLoading'])
        },

        apollo: {
            category: {
                query: gql`query category($slug: [String]) {
                    category: entry(slug: $slug, section: "documentCategories") {
                        id
                        title
                        slug
                        ... on documentCategories_documentCategories_Entry {
                            documentShowImageAsCardHeaderCover
                            documentCategoriesImage {
                                url
                            }
                        }
                        ancestors {
                            id
                            title
                            slug
                        }
                    }
                }`,
                variables(){
                    return {
                        slug : this.$route.params.slug
                    }
                },

                result(pResult){
                    if(!pResult.data.category) {
                        this.updateIsLoading(false);
                    }
                }
            },

            children: {
                skip() {
                    return !this.category;
                },
                fetchPolicy: 'no-cache',
                query : gql`query children($parentId: Int) {
                    children: documentCategories(childrenOf: $parentId) {
                        unreadDocumentCount
                        category {
                            id
                            title
                            uri
                            slug
                            lft
                            ... on documentCategories_documentCategories_Entry {
                                documentShowImageAsCardHeaderCover
                                documentCategoriesImage {
                                    url
                                }
                            }
                        }
                    }
                }`,
                variables(){
                    return {
                        parentId : parseInt(this.category.id, 10)
                    }
                },

                result(){
                    // Update loading state
                    this.updateIsLoading(false);

                    this.childrenRequestDone = true;
                },

                update(data) {
                    return data.children.sort((category1, category2) => {
                        if (category1.category.lft > category2.category.lft) {
                            return 1;
                        }

                        return -1;
                    });
                },
            },

            types : {
                skip() { return !this.category; },
                fetchPolicy: 'no-cache',
                query : gql`
                    query types($parentId: Int!) {
                        types: documentTypes(parentCategory: $parentId) {
                            unreadDocumentCount
                            type {
                                id
                                title
                                url
                                uri
                                slug
                                ... on documentTypes_documentTypes_Entry {
                                    documentTypesImage {
                                        url
                                    }
                                }
                            }
                        }
                    }
                `,
                variables(){
                    return {
                        parentId : parseInt(this.category.id, 10)
                    }
                },

                result() {
                    this.typesRequestDone = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .document-category {

        &__content {
            @apply mx-auto w-full;
            max-width: 1310px;
            padding: 40px 38px 0 40px;
        }

        .error-enter,
        .error-leave-to { opacity: 0 }

        .error-leave,
        .error-enter-to { opacity: 1 }

        .error-enter-active,
        .error-leave-active { transition: opacity 500ms; transition-delay: 2500ms }
    }
</style>
