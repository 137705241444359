import "./assets/styles/main.scss";

// this is a recommendation of babeljs (https://babeljs.io/docs/en/babel-polyfill)
// because @babel/polifill is deprecated
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from "vue";
import router from '@/app/router';
import store from '@/app/store';
import i18n from '@/app/i18n';
import App from '@/app/App';
import VueGlide from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';
import 'fetch-polyfill';
import 'es7-object-polyfill';
import 'jspolyfill-array.prototype.findIndex';

// CAROUSEL
Vue.use(VueGlide);

// FLASH MESSAGE
import VueFlashMessage from 'vue-flash-message';
Vue.use(VueFlashMessage);
require('vue-flash-message/dist/vue-flash-message.min.css');

// IDLE
import IdleVue from 'idle-vue';
const eventsHub = new Vue()

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 120000
});

// APOLLO
import apolloProvider from '@/app/apollo';


// Language change
router.beforeEach((pTo, pFrom, next) => {
    // If language parameter is different
    if(pFrom.params.lang !== pTo.params.lang) {
        // Update locale
        i18n.locale = pTo.params.lang;
    }

    if(pTo.matched.some(record => record.meta.requiresAuth)) {
        if (JSON.parse(localStorage.getItem('user'))) {
            next();
            return
        }

        // router.go({ name : 'login', params : { 'lang' : pFrom.params.lang }})
        next(pTo.params.lang + '/login');
    } else {
        next();
    }
});

new Vue({
    router,
    i18n,
    store,
    apolloProvider,
    render: h => h(App),
}).$mount('#app');
