<template>
    <div class="w-full mt-16">
        <div class="user-pwd-container bg-greylight3 rounded-md mx-auto pt-6 pb-12">
            <h2 class="text-text-greystrong text-center">{{ $t('account.reset-password') }}</h2>

            <form name="resetPasswordForm" class="mt-8" @submit.prevent="onSubmit">
                <div class="pwd-new">
                    <div class="field">
                        <label for="pwd">{{ $t('account.pwd-new') }} <sup>*</sup></label>
                        <input
                            id="pwd"
                            type="password"
                            name="password"
                            v-model="form.password"
                            class="input-text input-normal"
                            :placeholder="$t('account.min-eight-char')"
                            required
                        >
                        <p v-show="form.password.length > 0 && form.password.length < 8" class="error-block">{{ $t('account.pwd-short') }}</p>
                    </div>

                    <div class="field">
                        <label for="pwd-confirm">{{ $t('account.password-confirm') }}<sup>*</sup></label>
                        <input
                            id="pwd-confirm"
                            type="password"
                            name="passwordConfirm"
                            v-model="form.passwordConfirm"
                            class="input-text input-normal"
                            :placeholder="$t('account.min-eight-char')"
                            required
                        >
                        <p v-show="form.password !== form.passwordConfirm" class="error-block">{{ $t('account.pwd-match') }}</p>
                    </div>
                </div>


                <div class="change-pwd-submit flex justify-start items-center mt-2 text-center">
                    <button
                        type="submit"
                        class="btn btn--orange"
                        :disabled="isInvalid || isLoading"
                        :class="{
                            'is-loading': isLoading,
                            'is-valid': isPasswordChanged
                        }"
                    >{{ $t('account.submit') }}
                    </button>

                    <span class="change-pwd-required font-arial text-xxs text-text-grey ml-4">* {{ $t('account.required') }}</span>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import gql from "graphql-tag";

    export default {
        name : 'reset-password',

        data(){
            return {
                form : {
                    password : '',
                    passwordConfirm : ''
                },
                emailError : '',
                isPasswordChanged : false,
                isLoading : false,
                emailSent : false,
                isInvalid : false
            }
        },

        computed : {
            passwordShort(){
                return this.form.password.length < 8;
            },

            passwordMatch(){
                return this.form.password === this.form.passwordConfirm;
            },

            ...mapState(['flashSuccessTimeout', 'flashErrorTimeout'])
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(1);

            // Show breadcrumb
            this.updateBreadcrumbState(true);
        },

        methods : {
            ...mapMutations(['updateIsLoading']),

            onSubmit(){
                // If everything is ok
                if(!this.passwordShort && this.passwordMatch) {

                    // Update loading state
                    this.updateIsLoading(true);

                    // Do the mutation
                    this.$apollo.mutate({
                        mutation : gql`mutation SetPassword($password : String!, $code : String!, $id : String!) {
                            setPassword(
                                password: $password
                                code: $code
                                id: $id
                            )
                        }`,

                        variables : {
                            password : this.form.password,
                            code : this.$route.query.code,
                            id : this.$route.query.id,
                        }
                    }).then((result) => {
                        // Flash the success
                        this.$flashStorage.destroyAll();
                        this.flash(this.$t('flash.reset-password-success'), 'success', { timeout : this.flashSuccessTimeout});

                        // Update loading state
                        this.updateIsLoading(false);

                        this.$router.push({ name : 'login'});
                    }).catch((error) => {
                        console.log('error', error);

                        // Flash the error
                        this.flash(this.$t('flash.reset-password-error'), 'error', { timeout : this.flashErrorTimeout});

                        // Update loading state
                        this.updateIsLoading(false);
                    });
                }
            },

            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState'])
        }
    }
</script>

<style lang="scss">
.user-pwd-container {
    width: 451px;
    padding: 29px 24px 50px 24px;

    .form-forgot{
        width: 240px;
    }

    .error-block {
        @apply absolute mt-0 text-xxs text-purple font-frutiger-regular;
    }

    input {
        margin-top: 5px;
    }

    .field {
        margin-bottom: 35px;
    }
}
</style>
