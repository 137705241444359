import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from "../views/docs/Home.vue";
import DocumentCategory from "../views/docs/DocumentCategory.vue";
import DocumentType from "../views/docs/DocumentType.vue";
import PreHome from "../views/PreHome";
import Login from "../views/auth/Login";
import Logout from "../views/auth/Logout";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";
import CreateAccount from "../views/CreateAccount";
import News from "../views/News";
import NewsDetail from "../views/NewsDetail";
import UserSubscription from "../views/user/UserSubscription";
import UserPasswordChange from "../views/user/UserPasswordChange";
import UserManageProfile from "../views/user/UserManageProfile";
import ContentPage from "../views/ContentPage";
import SearchPage from "../views/SearchPage";
import PreInscription from "../views/auth/PreInscription";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    originalPush.call(this, location).catch(err => {
        if (err.name === 'NavigationDuplicated') {
            console.warn(err);
            return err;
        } else {
            throw err;
        }
    });
}

const routes = [
    {
        path : '/',
        redirect : '/en-gb'
    },
    {
        path: '/:lang',
        name: 'prehome',
        component : PreHome
    },
    {
        path: '/:lang/login',
        name: 'login',
        component : Login
    },
    {
        path: '/:lang/logout',
        name: 'logout',
        component : Logout
    },
    {
        path: '/:lang/forgot-password',
        name: 'forgot-password',
        component : ForgotPassword
    },
    {
        path: '/:lang/users/set-password',
        name: 'reset-password',
        component : ResetPassword
    },
    {
        path: '/:lang/create-account',
        name: 'create-account',
        component : CreateAccount
    },
    {
        path: '/:lang/pre-inscription',
        name: 'pre-inscription',
        component : PreInscription
    },
    {
        path: '/:lang/home',
        name: 'home',
        component : Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:lang/category/:slug',
        name: 'category',
        component : DocumentCategory,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:lang/category/:slug/type/:slug2',
        name: 'type',
        component : DocumentType,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:lang/news/:news_id',
        name: 'news-detail',
        component : NewsDetail,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:lang/news',
        name: 'news',
        component : News,
        meta: {
            requiresAuth: true
        }
    },
    {
        path : '/:lang/user/subscriptions',
        name : 'user-subscriptions',
        component : UserSubscription,
        meta: {
            requiresAuth: true
        }
    },
    {
        path : '/:lang/user/password',
        name : 'user-password',
        component : UserPasswordChange,
        meta: {
            requiresAuth: true
        }
    },
    {
        path : '/:lang/user/profile',
        name : 'user-profile',
        component : UserManageProfile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path : '/:lang/search',
        name : 'search',
        component : SearchPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path : '/:lang/:slug',
        name : 'content-page',
        component : ContentPage,
    },
];

const router = new VueRouter({
    routes,

    mode : 'history'
});


export default router;
