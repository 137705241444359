<template>
    <div class="create-account w-full pt-6 pb-6 mx-4 md:mx-0 overflow-hidden">
        <h2 class="create-account-title text-white text-center">{{ $t('account.create-account')}}</h2>

        <div class="signin-container w-full mt-6 bg-greylight3 rounded-md mx-auto">
            <div class="signin-header">
                <p class="m-0">{{ $t('account.create-account-legend') }}</p>
                <p class="mt-2">{{ $t('account.fill-activation-key') }}</p>
            </div>

            <form v-if="countries" @submit.prevent="onSubmit" class="mt-8">
                <div class="field">
                    <label for="civility">{{ $t('account.civility') }}<sup>*</sup></label>
                    <input
                        type="text"
                        name="civility"
                        id="civility"
                        v-model="form.civility"
                        required
                        class="input-text input-normal"
                    >
                </div>

                <div class="flex flex-col md:flex-row items-start">

                    <div class="field">
                        <label for="firstname">{{ $t('account.firstname') }}<sup>*</sup></label>
                        <input
                            type="text"
                            name="firstname"
                            id="firstname"
                            v-model="form.firstname"
                            required
                            class="input-text input-normal"
                        >
                    </div>

                    <div class="field">
                        <label for="lastname">{{ $t('account.lastname') }}<sup>*</sup></label>
                        <input
                            type="text"
                            name="lastname"
                            id="lastname"
                            v-model="form.lastname"
                            required
                            class="input-text input-normal"
                        />
                    </div>

                </div>

                <div class="flex flex-col md:flex-row items-start">
                    <div class="field">
                        <label for="email">{{ $t('account.e-mail') }}<sup>*</sup></label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                            v-model="form.email"
                            required
                            :class="{'error' : form.email !== form.emailConfirm}"
                            class="input-text input-normal email"
                        >
                    </div>

                    <div class="field">
                        <label for="emailConfirm">{{ $t('account.e-mail-confirm') }}<sup>*</sup></label>
                        <input
                            type="email"
                            name="emailConfirm"
                            id="emailConfirm"
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                            v-model="form.emailConfirm"
                            required
                            :class="{'error' : form.email !== form.emailConfirm}"
                            class="input-text input-normal email"
                        >
                        <div v-show="form.email !== form.emailConfirm" class="error-block">{{ $t('account.e-mail-match')}}</div>
                    </div>
                </div>

                <div class="flex flex-col md:flex-row items-start">
                    <div class="field">
                        <label for="pwd">{{ $t('account.password') }}<sup>*</sup></label>
                        <input
                            type="password"
                            name="pwd"
                            id="pwd"
                            v-model="form.pwd"
                            :placeholder="$t('account.min-eight-char')"
                            minlength="8"
                            required
                            :class="{'error'  : form.pwd !== form.pwdConfirm}"
                            class="input-text input-normal"
                        >
                        <div v-if="form.pwd.length > 0 && form.pwd.length < 8" class="error-block">{{ $t('account.pwd-short')}}</div>
                    </div>

                    <div class="field">
                        <label for="pwdConfirm">{{ $t('account.password-confirm') }}<sup>*</sup></label>
                        <input
                            type="password"
                            name="pwdConfirm"
                            id="pwdConfirm"
                            :placeholder="$t('account.min-eight-char')"
                            v-model="form.pwdConfirm"
                            required
                            :class="{'error'  : form.pwd !== form.pwdConfirm}"
                            class="input-text input-normal"
                        >
                        <div v-show="form.pwd !== form.pwdConfirm"  class="error-block">{{ $t('account.pwd-match')}}</div>
                    </div>

                </div>

                <div class="flex flex-col md:flex-row items-start">

                    <div class="field">
                        <label for="establishment">{{ $t('account.institution') }}<sup>*</sup></label>
                        <input
                            type="text"
                            name="establishment"
                            id="establishment"
                            v-model="form.establishment"
                            required
                            class="input-text input-normal"
                        >
                    </div>

                    <div class="field">
                        <label for="lab">{{ $t('account.lab-name') }}<sup>*</sup></label>
                        <input
                            type="text"
                            name="lab"
                            id="lab"
                            v-model="form.lab"
                            required
                            class="input-text input-normal"
                        >
                    </div>
                </div>

                <div class="field">
                    <label for="occupation">{{ $t('account.occupation') }}<sup>*</sup></label>
                    <input
                        type="text"
                        name="occupation"
                        id="occupation"
                        v-model="form.occupation"
                        required
                        class="input-text input-normal"
                    >

                </div>

                <div class="flex flex-col md:flex-row items-start">
                    <div class="field">
                        <label for="city">{{ $t('account.city') }}<sup>*</sup></label>
                        <input
                            type="text"
                            name="city"
                            id="city"
                            v-model="form.city"
                            required
                            class="input-text input-normal"
                        >
                    </div>

                    <div class="field">
                        <label for="cp">{{ $t('account.postal-code') }}<sup>*</sup></label>
                        <input
                            type="text"
                            name="cp"
                            id="cp"
                            v-model="form.cp"
                            required
                            class="input-text input-normal code-postal"
                        >
                    </div>
                </div>

                <div class="flex items-start">
                    <div class="field">
                        <label class="pays">{{ $t('account.country') }}<sup>*</sup></label>
                        <select
                            class="select mt-"
                            v-model="form.country"
                            required
                        >
                            <option v-for="(item, index) in countries" :key="'coutryselectitem'+index" :value="item.iso2" >
                                {{ item.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="key">
                    <div class="field">
                        <label for="activationKey">{{ $t('account.activation-key') }}</label>
                        <input
                            type="text"
                            name="activationKey"
                            id="activationKey"
                            :placeholder="$t('account.twelve-characters')"
                            v-model="form.activationKey"
                            minlength="9"
                            maxlength="12"
                            class="input-text input-normal"
                        >
                        <p class="question mt-4"><strong>{{ $t('account.activation-key-question') }}</strong></p>
                        <p>{{ $t('account.activation-key-legend') }}</p>
                    </div>

                </div>

                <div class="signin-submit flex items-center justify-center mt-8">
                    <button
                        type="submit"
                        class="btn btn--orange"
                        :class="{
                            'is-loading': isSaving
                        }"
                    >{{ $t('account.submit') }}
                    </button>
                    <span class="signin-required font-arial text-xxs text-text-grey ml-3">* {{ $t('account.required') }}</span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import gql from "graphql-tag";

export default {
    name : 'create-account',

    data(){
        return {
            form  : {
                civility : '',
                firstname : '',
                lastname : '',
                email : '',
                emailConfirm : '',
                username : '',
                pwd : '',
                pwdConfirm : '',
                establishment : '',
                lab : '',
                occupation : '',
                city : '',
                cp : '',
                country : '',
                activationKey : ''
            },
            isSaving : false,
            activationKeyIsLoading : false,
            activationKeyValid : false,
            countriesItems : [
                {
                    id : 'fr',
                    name : 'France'
                }
            ]
        }
    },

    computed : {
        emailMatch(){
            return this.form.email === this.form.emailConfirm;
        },

        pwdMatch(){
            return this.form.pwd === this.form.pwdConfirm;
        },

        pwdShort(){
            return this.form.pwd.length < 8;
        },

        ...mapState(['countries', 'flashSuccessTimeout', 'flashErrorTimeout'])
    },

    mounted(){
        // Set the default background
        this.updateMainBackgroundState(1);

        // Show breadcrumb
        this.updateBreadcrumbState(true);

        // Update breadcrumb content
        this.updateBreadcrumb([
            {
                url : { name : 'prehome', params : { lang : this.$i18n.locale}},
                label : 'breadcrumb.home',
                i18n: true
            }, {
                url : null,
                label : 'account.create-account',
                i18n: true
            }
        ]);
    },

    methods : {
        async onSubmit() {
            if (this.pwdMatch && this.emailMatch && !this.pwdShort) {

                // Update loading state
                this.updateIsLoading(true);

                //Launch mutation
                try {
                    const result = await this.$apollo.mutate({
                        mutation:  gql`mutation($email : String!, $password : String!, $civility : String, $firstname : String!, $lastname : String!, $establishment : String, $lab : String, $occupation : String, $city: String, $cp  : String, $country  : String, $usersPreferredLocale : String){
                                register(
                                    email: $email
                                    password: $password,
                                    usersCivility : $civility,
                                    firstName : $firstname,
                                    lastName : $lastname,
                                    usersInstitution : $establishment,
                                    usersLaboratory : $lab,
                                    usersOccupation : $occupation,
                                    usersCity : $city,
                                    usersPostalCode : $cp,
                                    usersCountry : $country,
                                    usersPreferredLocale : $usersPreferredLocale
                                ) {
                                    jwt
                                    jwtExpiresAt
                                    refreshToken
                                    refreshTokenExpiresAt
                                    user {
                                      id
                                      fullName
                                    }
                                }
                            }`,
                        variables: {
                            email:         this.form.email,
                            password:      this.form.pwd,
                            civility:      this.form.civility,
                            firstname:      this.form.firstname,
                            lastname:      this.form.lastname,
                            establishment: this.form.establishment,
                            lab:           this.form.lab,
                            occupation:    this.form.occupation,
                            city:          this.form.city,
                            cp:            this.form.cp,
                            country:       this.form.country,
                            usersPreferredLocale : this.$route.params.lang.replace('-','')
                        }
                    });

                    // Flash the success
                    this.flash(this.$t('flash.create-account-success'), 'success', { timeout : this.flashSuccessTimeout});

                    // Update loading state
                    this.updateIsLoading(false);

                    if (this.form.activationKey) {

                        await this.setActivationKey(result.data.register.user.id);
                        return;
                    }

                    // Redirect to home
                    this.$router.push({ name : 'pre-inscription',  query : { email : this.form.email }, params : { lang : this.$route.params.lang}});
                } catch (error) {
                    // Flash the error
                    this.flash(this.$t('flash.error') + ': ' + this.$t(error.toString().replace('Error: GraphQL error: ', '').trim()), 'error', { timeout: this.flashErrorTimeout });

                    this.updateIsLoading(false);
                }
            }
        },

        async setActivationKey(userId) {
            // Try to save activation key
            try{
                const setActivationKeyResult = await this.$apollo.mutate({
                    mutation : gql`mutation($userId : Int!, $activationKey : String!){
                                setUserActivationKey(userId: $userId, activationKey: $activationKey)
                            }`,
                    variables : {
                        userId : parseInt(userId),
                        activationKey : this.form.activationKey
                    }
                });

                // Redirect to home
                this.$router.push({ name : 'login', params : { lang : this.$route.params.lang}});
            } catch (error) {
                // Flash the error
                this.flash(this.$t('flash.activation-key-fail'), 'error', { timeout : this.flashErrorTimeout});

                // Error
                console.error('Error activation key');
            }
        },

        ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb', 'updateUser', 'updateIsAuthenticated', 'updateIsLoading'])
    }
}
</script>

<style lang="scss">

.signin-container {
    max-width: 696px;
    padding: 25px 24px 50px 24px;



    .error-block {
        @apply mt-3;
    }

    label {
        @apply font-arial font-bold text-sm text-greystrong2;
    }

    input, select {
        @apply relative mr-2 mt-2;
    }

    .field {
        @apply mb-4;

        @screen md {
            @apply mb-8;
        }
    }

    .usernameIsLoading {
        width: 40px;
        height: 40px;
    }

    .code-postal {
        width: 120px;
    }
}
</style>
