<template>
    <div ref="breadcrumb" class="breadcrumb relative bg-orange w-full flex items-center overflow-x-scroll">
        <ul class="list px-8 list-none flex items-center">
            <li v-for="(item, index) in breadcrumb" :key="'breadcrumb-item-'+index" class="item relative block flex items-center">
                <router-link
                    v-if="item.url"
                    :to="item.url"
                    :class="{'not-active': index !== breadcrumb.length -1, 'is-active': index === breadcrumb.length -1, 'first-item': index === 0}"
                    class="font-arial font-bold text-xs text-white uppercase leading-normal whitespace-nowrap"
                >{{ item.i18n ? $t(item.label) : item.label }}</router-link>
                <p
                    v-if="!item.url"
                    :class="{'not-active': index !== breadcrumb.length -1, 'is-active': index === breadcrumb.length -1, 'first-item': index === 0}"
                    class="font-arial font-bold text-xs text-white uppercase leading-normal whitespace-nowrap"
                >{{ item.i18n ? $t(item.label) : item.label }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { gsap } from 'gsap';

    export default {
        name : 'breadcrumb',

        computed : {
            ...mapState(['breadcrumbState', 'breadcrumb'])
        },

        watch : {
            breadcrumbState(){
                this.updateBreadcrumb();
            }
        },

        mounted(){
            this.updateBreadcrumb();
        },

        methods : {
            updateBreadcrumb(){
                gsap.to(this.$refs.breadcrumb, {
                    y : (this.breadcrumbState) ? '0%' : '-100%'
                });
            }
        }
    }
</script>

<style lang="scss">
    .breadcrumb::-webkit-scrollbar {
        display: none;
    }

    .breadcrumb {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .breadcrumb-enter-active, .breadcrumb-leave-active {
        transition: transform 0.4s ease-out;
    }
    .breadcrumb-enter, .breadcrumb-leave-to /* .fade-leave-active below version 2.1.8 */ {
        transform : translateY(-100%);
    }
</style>
