<template>
    <div class="document-grid" :id="'group_'+groupId">
        <h2 class="text-white mt-3 mb-4 md:mt-5 md:mb-6">{{ groupName }}</h2>

        <div class="document-grid__content flex flex-wrap justify-start md:justify-start md:items-stretch">
            <div
                v-for="(card,index) in documents"
                :key="'card'+index"
                :class="{ 'show-image': true}"
                class="item document-grid__document-item"
            >
                <document-card
                    :card="card"
                    :show-image="false"
                    class="h-full"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DocumentCard from "./DocumentCard";

    export default {
        name : 'documents-group',

        components : { DocumentCard },

        props : {
            groupName : { type : String, default : ''},
            groupId : { type : String, default : ''},
            documents : { type : Array, default : () => []}
        }
    }
</script>

<style lang="scss">
    .document-grid {
        border-top : 1px solid rgba(255, 255, 255, 0.3);
        margin-bottom : 40px;

        &__document-item {
            flex : 0 0 calc(25% - 8px);
            margin-right : 8px;
            margin-bottom: 18px;
        }
    }
</style>
