<template>
    <div class="news-detail w-full py-6">
        <news-navigation></news-navigation>

        <div class="news-content mx-auto bg-greylight3 px-4 py-6 rounded-md my-8">
            <div v-if="isLoading" class="loading-container">
                <div class="loading-background">
                    <img src="~@/assets/images/common/loader.gif" />
                </div>
            </div>

            <div class="news-date font-arial text-xxs text-text-grey text-right">{{news.created}}</div>

            <h1 class="h3 text-text-greystrong">{{news.name}}</h1>

            <div v-html="news.chapo" class="font-frutiger-regular text-lg text-text-greystrong"></div>
            <div v-html="news.description" class="article mt-10 cms-content"></div>
        </div>

        <news-navigation></news-navigation>
    </div>
</template>

<script>
    import NewsNavigation from "../components/NewsNavigation";

    export default {
        name : 'news-detail',

        components : { NewsNavigation },

        data(){
            return {
                isLoading : false,

                news : {
                    name : 'New UKAS Website Launched Today',
                    created : '2015-10-22 14:39:08',
                    chapo : 'UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation',
                    description : 'UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation UKAS currently managing the transition of all CPA accredited laboratories to UKAS accreditation',
                    slug : 'tutu',

                }
            }
        }
    }
</script>

<style lang="scss">
.news-content {
    width: 696px;

    .article {

    }

}
</style>
