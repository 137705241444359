<template>
    <div class="user-container w-full py-6 md:mx-0 overflow-hidden" :class="{'px-4': withPadding}">
        <h2 class="text-white text-center">{{ $t('profile.my-profile')}}</h2>

        <div :class="{'px-4': !withPadding}">
            <div class="user-tabs mt-8 mx-auto rounded flex flex-col md:flex-row items-center justify-center text-center text-text-greystrong no-underline">
                <router-link
                    v-for="(item, index) in tabs"
                    :key="'tab' + index"
                    :to="{ name : item.slug}"
                    :class="{'active': $route.name === item.slug, 'first': index === 0, 'notFirst': index !== 0, 'last': index === tabs.length -1, 'center': index !== tabs.length -1 && index !== 0}"
                    class="tab subscription-tab h6"
                >
                    {{ $t(item.name) }}
                </router-link>
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script>
    import {mapMutations} from "vuex";

    export default {
        name : 'user-template',

        props: {
            withPadding: {
                type: Boolean,
                default: true,
            },
        },

        data(){
            return {
                tabs : [
                    {
                        name : 'profile.manage-subscriptions',
                        slug : 'user-subscriptions',
                    },
                    {
                        name : 'profile.change-password',
                        slug : 'user-password',
                    },
                    {
                        name : 'profile.manage-profile',
                        slug : 'user-profile',
                    }
                ]
            }
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(2);

            // Show breadcrumb
            this.updateBreadcrumbState(true);
        },

        methods : {
            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState'])
        }
    }
</script>

<style lang="scss">
.user-container {
    .user-tabs {
        @apply w-full;
        background-color : rgba(255,255,255,0.3);

        @screen md {
            @apply bg-transparent;
            background-image: url('~@/assets/images/common/user-tabs-background.png');
            width: 842px;
            height: 40px;
        }

        .tab {
            @apply w-full;
            height: 40px;
            line-height : 40px;

            @screen md {
                width: 280px;
            }
        }

        .first {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            &.active {
                background-color : rgba(255,255,255,0.4);
                height: 48px;
                margin-top : 7px;

                @screen md {
                    @apply bg-transparent;
                    background-image: url('~@/assets/images/common/user-active-tab-left.png');
                }
            }
        }
        .last {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            margin-left: 1px;

            &.active {
                background-color : rgba(255,255,255,0.4);
                margin-top : 7px;
                height: 48px;

                @screen md {
                    @apply bg-transparent;
                    background-image: url('~@/assets/images/common/user-active-tab-right.png');
                }
            }
        }
        .center {
            margin-left: 1px;

            &.active {

                margin-top : 7px;
                height: 48px;

                @screen md {
                    @apply bg-transparent;
                    background-image: url('~@/assets/images/common/user-active-tab-center.png');
                }
            }
        }
    }

}
</style>
