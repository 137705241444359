<template>
    <user-template :with-padding="false">
        <section class="user-subscriptions user-subscriptions-container">
            <vue-glide
                :options="carouselOptions"
                v-model="currentSlideId"
            >
                <!-- === HELP === -->
                <vue-glide-slide>
                    <div class="block">
                        <div class="home-block">
                            <img
                                class="w-full"
                                src="~@/assets/images/common/subscriptions-img.png"
                            />
                            <div class="description">
                                <p class="h7">{{ $t('subscription.how-it-work') }}</p>
                                <p class="body2">
                                    {{ $t('subscription.subscription-description-first') }}<br/>
                                    {{ $t('subscription.subscription-description-second') }}
                                </p>
                                <div class="legend mt-4">
                                    <div class="subscribed">
                                        {{ $t('subscription.subscribed') }}
                                    </div>
                                    <div class="semi-subscribed">
                                        {{ $t('subscription.subscribed-partly') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-glide-slide>

                <!-- === SUBSCRIPTIONS === -->
                <vue-glide-slide
                    v-for="i in 10"
                    :key="'panel'+i"
                    class="block"
                    :class="{'subscription-panel': (panels.length > 2)}"
                >
                    <div v-if="panels[i-1] !== undefined" class="subscriptions-block">
                        <div v-if="i === 0" class="block-description">
                            <p class="body2">{{ $t('subscription.subscription-legend') }}</p>
                        </div>
                        <div
                            v-for="(item, j) in panels[i-1]"
                            :key="'item' + j"
                            :class="{ active: isSelected(item.id), hasChild: item.children.length > 0 }"
                            class="list-item flex flex-nowrap items-center"
                        >
                            <div
                                :class="{
                                'isLoading': item.id === currentLoadingId,
                                'checked': item.subscribed,
                                'semiChecked': !item.subscribed && item.subsCount > 0,
                                'disabled': item.hasSubscribedAncestor,
                            }"
                                class="bd-subscribe-doc doc-checkbox checkbox cursor-pointer flex items-center"
                                @click="toggleSubscription(item)"
                            >
                                <div
                                    class="checkbox__tick flex justify-center items-center bg-white border border-solid border-trace-greyslight2 rounded-sm">
                                    <span class="checkbox__icon icon"></span>
                                </div>
                            </div>

                            <div
                                class="link body flex items-center"
                                @click="selectNode(item, i-1)"
                            >
                                <div class="subscription-title" :title="`id: #${item.id}, type: ${item.__typename.split('_')[0]}`">
                                    {{ item.title }}
                                </div>
                                <div
                                    class="arrow"
                                    v-if="item.children.length > 0"
                                ></div>
                            </div>
                        </div>

                        <div
                            class="back-layer hidden lg:block"
                            v-if="i === panels.length-3 && panels.length > 3"
                            @click="goBack()"
                        >
                            <div class="layer"></div>
                            <div class="back"></div>
                        </div>
                    </div>
                </vue-glide-slide>
            </vue-glide>
        </section>
    </user-template>
</template>

<script>
import {mapMutations, mapState, mapGetters, mapActions} from "vuex";
import UserTemplate from "./UserTemplate";
import gql from "graphql-tag";

import {arrayToTree} from 'performant-array-to-tree';

export default {
    name: 'user-subscription',

    components: {
        UserTemplate,
    },

    data() {
        return {
            maxPanelCount: 2,
            flatCategories: new Map(),
            flatTypes: new Map(),
            flatDocuments: [],
            tree: [],
            breadcrumbs: [],

            carouselOptions: {
                perView: 4,
                gap: 15,
                bound: true,
                dragThreshold: false,
                breakpoints: {
                    992: {
                        perView: 1,
                    },
                },
            },
            currentSlideId: 0,

            currentLoadingId: null,
        }
    },

    computed: {
        ...mapState('subscriptions', ['subscriptions']),
        ...mapGetters('subscriptions', ['isSubscribed']),

        panels() {
            const panels = [this.tree];

            let i = 0;
            let currentNode = this.tree;
            for (const id of this.breadcrumbs) {
                panels[++i] = currentNode.find(item => item.id == id).children;
                currentNode = panels[i];
            }

            panels.length = this.breadcrumbs.length + 1;

            return panels;
        },
    },

    watch: {
        subscriptions() {
            this.setTree();
        }
    },

    mounted() {
        // Set the default background
        this.updateMainBackgroundState(2);

        // Show breadcrumb
        this.updateBreadcrumbState(true);

        // Update breadcrumb content
        this.updateBreadcrumb([
            {
                url: {name: 'home', params: {lang: this.$i18n.locale}},
                label : 'breadcrumb.home',
                i18n: true
            }, {
                url: null,
                label : 'profile.manage-subscriptions',
                i18n: true
            }
        ]);

        this.fetchDocumentTree();
    },

    methods: {
        ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb']),
        ...mapMutations('subscriptions', ['addSubscription']),
        ...mapActions('subscriptions', ['saveSubscriptions']),

        createSubscriptionObject(item) {
            const type = item.__typename.split('_')[0];
            const subscription = {};
            switch (type) {
                case 'documents':
                    subscription.type = 'document';
                    subscription.documentId = item.id;
                    break;
                case 'documentCategories':
                    subscription.type = 'documentCategory';
                    subscription.documentCategoryId = item.id;
                    break;
                case 'documentTypes':
                    const parentCategory = this.getParentCategory(item);
                    if (!parentCategory) {
                        throw new Error(`Item refers to an unknown category: ${item.title} (#${item.id})`);
                    }
                    subscription.type = 'documentType';
                    subscription.documentTypeId = item.id;
                    subscription.documentCategoryId = parentCategory.id;
                    break;
                default:
                    console.error(item.__typename);
                    throw new Error('Unexpected subscription format');
            }

            return subscription;
        },

        /**
         * Set a reactive category tree from flat categories with correct subscription icons
         */
        setTree() {
            const flatTree = new Map([
                ...Array.from(this.flatCategories.entries()).map(([key, value]) => [key, {...value}]),
                ...Array.from(this.flatTypes.entries()).map(([key, value]) => [key, {...value}]),
                ...this.flatDocuments.map(item => [ item.treeId, item ]),
            ]);

            flatTree.forEach(value => {
                value.subsCount = 0;
                value.subscribed = false;
            });

            const tree = arrayToTree(Array.from(flatTree.values()), {
                id: 'treeId',
                parentId: 'parentTreeId',
                childrenField: 'children',
                dataField: null,
            });

            this.orderTree(tree);

            tree.forEach(node => {
                this.updateSubscribedProperty(node);
            });

            tree.forEach((node) => {
                this.checkChildrenSubscriptions(node);
                this.checkAncestorsSubscriptions(node);
            });

            this.tree = tree;
        },

        /**
         * Recursively walks a node's children and set the subscribed property on each node
         * @param node
         */
        updateSubscribedProperty(node) {
            node.children.forEach(childNode => {
                this.updateSubscribedProperty(childNode);
            });
            node.subscribed = this.isSubscribed(this.createSubscriptionObject(node));
        },

        /**
         * Recursively walks a node's children and increment the subsCount if the element is directly subscribed or has children that are subscribed
         * @param node
         */
        checkChildrenSubscriptions(node) {
            node.children.forEach((childNode) => {
                if(childNode.subsCount === 0) {
                    this.checkChildrenSubscriptions(childNode);
                }

                if (childNode.subscribed || childNode.subsCount) {
                    node.subsCount++;
                }
            });
        },

        /**
         * Recursively walks a node's children and set the hasSubscribedAncestor property if the node is directly subscribed or has a subscribed ancestor.
         * @param node
         */
        checkAncestorsSubscriptions(node) {
            node.children.forEach((childNode) => {
                childNode.hasSubscribedAncestor = node.subscribed || node.hasSubscribedAncestor;
                this.checkAncestorsSubscriptions(childNode);
            });
        },

        /**
         * Recursively walks the tree and order the children according to their lft property
         * @param nodes
         */
        orderTree(nodes) {
            nodes.sort((childNode1, childNode2) => childNode1.lft > childNode2.lft ? 1 : -1);
            nodes.forEach(node => this.orderTree(node.children));
        },

        /**
         * Select current node to reveal his children
         * Update carousel position if needed
         * @param node
         * @param level
         */
        selectNode(node, level) {
            if (node.children.length === 0) {
                return;
            }

            this.currentSlideId = window.matchMedia("(min-width: 992px)").matches ? level - 1 : level + 2;

            this.breadcrumbs.length = level + 1;
            this.$set(this.breadcrumbs, level, node.id);
        },

        /**
         * Go back into category tree, desktop only
         */
        goBack() {
            this.currentSlideId--;

            if (this.breadcrumbs) {
                this.breadcrumbs.pop();
            }
        },

        /**
         * subscribe or unsubscribe user from a category
         * @param item
         */
        async toggleSubscription(item) {
            if (item.hasSubscribedAncestor) {
                return;
            }

            this.currentLoadingId = item.id;

            const subscription = this.createSubscriptionObject(item);
            this.$store.dispatch('subscriptions/toggleSubscription', subscription);
            await this.saveSubscriptions();
            this.currentLoadingId = null;
        },

        async fetchDocumentTree() {
            /**
             * @type {Array}
             */
            const results = await this.$apollo.query({
                query: gql`
                    query documentTree {
                        documentTree {
                            id
                            title
                            parent {id}
                            lft
                            ...on documents_file_Entry {
                                documentsType {id}
                                documentsCategories {id}
                            }
                            ...on documents_cta_Entry {
                                documentsType {id}
                                documentsCategories {id}
                            }
                        }
                    }
                `,
            });

            const types = new Map();

            // Loop over categories and types
            results.data.documentTree.forEach((item) => {
                switch (item.__typename) {
                    case 'documents_cta_Entry':
                    case 'documents_file_Entry':
                        break;
                    case 'documentTypes_documentTypes_Entry':
                        types.set(item.id, item);
                        break;
                    case 'documentCategories_documentCategories_Entry':
                        item.treeId = item.id;
                        if (item.parent) {
                            item.parentTreeId = item.parent.id;
                        }
                        this.flatCategories.set(item.treeId, {
                            __typename: item.__typename,
                            id: item.id,
                            title: item.title,
                            treeId: item.id,
                            parentTreeId: item.parent && item.parent.id ? item.parent.id : null,
                            lft: item.lft,
                        });
                        break;
                }
            });

            // Loop over documents
            results.data.documentTree.forEach((item) => {
                if (['documentTypes_documentTypes_Entry', 'documentCategories_documentCategories_Entry'].includes(item.__typename)) {
                    return;
                }

                const currentType = { ...types.get(item.documentsType[0].id)};
                if (!currentType) {
                    console.error('TYPE DOES NOT EXIST', item.documentsType[0].id);
                    return;
                }

                let parentType = null;

                if (currentType.parent) {
                    parentType = {...types.get(currentType.parent.id)};
                }

                item.documentsCategories.forEach((category) => {
                    if (parentType) {
                        // Level-2 type
                        parentType.treeId = `${category.id}_${parentType.id}`;
                        parentType.parentTreeId = category.id;
                        this.pushType(parentType);

                        currentType.treeId = `${parentType.id}_${currentType.id}`;
                        currentType.parentTreeId = parentType.treeId;
                    } else {
                        // Level-1 type
                        currentType.treeId = `${category.id}_${currentType.id}`;
                        currentType.parentTreeId = category.id;
                    }
                    this.pushType(currentType);

                    const document = {...item};
                    document.parentTreeId = currentType.treeId;
                    document.treeId = `${currentType.treeId}_${document.id}`;

                    this.flatDocuments.push(document);
                });
            });

            this.setTree();
        },

        pushType(type) {
            if (!this.flatTypes.has(type.treeId)) {
                // Deep clone the type to avoid nasty surprises
                this.flatTypes.set(type.treeId, {
                    title: type.title,
                    __typename: type.__typename,
                    id: type.id,
                    treeId: type.treeId,
                    parentTreeId: type.parentTreeId,
                    lft: type.lft,
                });
            }
        },

        isSelected(id) {
            return this.breadcrumbs.includes(id);
        },

        /**
         * Get the parent category of a type or category.
         * If the item is a type, the parent category may not be its direct parent.
         * @param item
         * @returns {null|*}
         */
        getParentCategory(item) {
            if (!item.parentTreeId) {
                return null;
            }

            if (this.flatTypes.has(item.parentTreeId)) {
                item = this.flatTypes.get(item.parentTreeId);
            }

            if (this.flatCategories.has(item.parentTreeId)) {
                return this.flatCategories.get(item.parentTreeId);
            }

            return null;
        },
    }
}
</script>

<style lang="scss">
.user-subscriptions {
    .glide__track {
        overflow: visible;
    }
}
</style>
