<template>
    <user-template>
        <div class="user-profil-container w-full my-10 bg-greylight3 mx-auto rounded-md">
            <p class="body2 px-6 py-4 self-start">{{ $t('account.fill-activation-key') }}</p>
            <p v-if="$t('profile.manage-profile-legend')" class="legend">{{ $t('profile.manage-profile-legend')}}</p>

            <form v-if="viewer && countries && langs" @submit.prevent="updateUser">
                <div class="field">
                    <label for="civility">{{ $t('account.civility')}}</label>
                    <input
                        type="text"
                        name="civility"
                        v-model="viewer.usersCivility"
                        required
                        id="civility"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="firstname">{{ $t('account.firstname')}}</label>
                    <input
                        type="text"
                        name="firstname"
                        v-model="viewer.firstName"
                        required
                        id="firstname"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="lastname">{{ $t('account.lastname')}}</label>
                    <input
                        type="text"
                        name="lastname"
                        v-model="viewer.lastName"
                        required
                        id="lastname"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="email">{{ $t('account.e-mail')}}</label>
                    <input
                        type="email"
                        name="email"
                        v-model="viewer.email"
                        required
                        id="email"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="institution_name">{{ $t('account.institution')}}</label>
                    <input
                        type="text"
                        name="institution_name"
                        v-model="viewer.usersInstitution"
                        required
                        id="institution_name"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="laboratory_name">{{ $t('account.lab-name')}}</label>
                    <input
                        type="text"
                        name="laboratory_name"
                        v-model="viewer.usersLaboratory"
                        required
                        id="laboratory_name"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="occupation">{{ $t('account.occupation')}}</label>
                    <input
                        type="text"
                        name="occupation"
                        v-model="viewer.usersOccupation"
                        required
                        id="occupation"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="city">{{ $t('account.city')}}</label>
                    <input
                        type="text"
                        name="city"
                        v-model="viewer.usersCity"
                        required
                        id="city"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="postal_code">{{ $t('account.postal-code')}}</label>
                    <input
                        type="text"
                        name="postal_code"
                        v-model="viewer.usersPostalCode"
                        required
                        id="postal_code"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <label for="country">{{ $t('account.country')}}</label>
                    <select
                        id="country"
                        class="select"
                        name="country"
                        v-model="country"
                        required
                    >
                        <template v-for="(item, index) in countries" >

                            <option
                                :key="'optionitem'+index"
                                :value="item.iso2"
                            >{{ item.name }}</option>

                        </template>
                    </select>
                </div>

                <div class="field">
                    <label for="activationKey">{{ $t('account.activation-key') }}</label>
                    <input
                        type="text"
                        name="activationKey"
                        id="activationKey"
                        :placeholder="$t('account.twelve-characters')"
                        v-model="viewer.usersActivationKey[0].title"
                        minlength="9"
                        maxlength="12"
                        class="input-text input-normal"
                    >
                </div>

                <div class="field">
                    <checkbox
                        class="bd-checkbox doc-checkbox"
                        :label="$t('account.usersAutomaticallySubscribeToDocuments')"
                        :check-style="'check'"
                        :selected="(viewer.usersAutomaticallySubscribeToDocuments) ? 1 : 0"
                        @check-toggled="usersAutomaticallySubscribeToDocumentsChecked"
                    ></checkbox>
                </div>

                <div class="language-notif">
                    <p>{{ $t('account.notification-language')}}</p>

                    <select
                        class="select notifSelect"
                        name="subscription_language"
                        v-model="preferredLocale"
                        required
                    >
                        <option v-for="(item, index) in langs" :key="'languageitem'+index" :value="item.code">{{ item.label }}</option>
                    </select>
                </div>

                <div class="user-profile-submit flex justify-center">
                    <button
                        type="submit"
                        class="btn btn--orange submit"
                        :class="{
                            'is-loading': userData.isSaving,
                            'is-valid': userData.isSaved
                        }"
                    >
                        {{ $t('account.submit')}}
                    </button>
                </div>
            </form>
        </div>
    </user-template>
</template>

<script>
    import gql from 'graphql-tag';
    import { mapState, mapMutations } from 'vuex';
    import UserTemplate from "./UserTemplate";
    import Checkbox from "../../components/Checkbox";

    export default {
        name : 'user-manage-profile',

        components : {
            UserTemplate,
            Checkbox
        },

        data(){
            return {
                userData : {
                    item : {
                        civility : '',
                        firstname : '',
                        lastname : '',
                        email : '',
                        institution_name : '',
                        laboratory_name : '',
                        occupation : '',
                        city : '',
                        postal_code : '',
                        pays_id : '',
                        activation_key : '',
                        subscription_language : '',
                    },

                    isSaving : false,
                    isSaved : false
                },
                country : '',
                preferredLocale : ''
            }
        },

        computed : {
            ...mapState(['user', 'langs', 'countries', 'flashSuccessTimeout' , 'flashErrorTimeout'])
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(2);

            // Show breadcrumb
            this.updateBreadcrumbState(true);

            // Update breadcrumb content
            this.updateBreadcrumb([
                {
                    url : { name : 'home', params : { lang : this.$i18n.locale}},
                    label : 'breadcrumb.home',
                    i18n: true
                }, {
                    url : null,
                    label : 'profile.manage-profile',
                    i18n: true
                }
            ]);

            // Update loading state
            this.updateIsLoading(true);
        },

        methods:  {
            updateUser(){
                // Update loading state
                this.updateIsLoading(true);

                // Launch mutation
                this.$apollo.mutate({
                    mutation : gql`mutation UpdateViewer($email : String!, $civility : String, $firstname : String!, $lastname : String!, $establishment : String, $lab : String, $occupation : String, $city: String, $cp  : String, $country  : String, $usersPreferredLocale : String, $usersAutomaticallySubscribeToDocuments : Boolean) {
                      updateViewer(
                        email: $email
                        usersCivility : $civility,
                        firstName : $firstname,
                        lastName : $lastname,
                        usersInstitution : $establishment,
                        usersLaboratory : $lab,
                        usersOccupation : $occupation,
                        usersCity : $city,
                        usersPostalCode : $cp,
                        usersCountry : $country,
                        usersPreferredLocale : $usersPreferredLocale,
                        usersAutomaticallySubscribeToDocuments : $usersAutomaticallySubscribeToDocuments
                      ) {
                        id
                      }
                    }`,
                    variables : {
                        email : this.viewer.email,
                        civility : this.viewer.usersCivility,
                        firstname : this.viewer.firstName,
                        lastname : this.viewer.lastName,
                        establishment : this.viewer.usersInstitution,
                        lab : this.viewer.usersLaboratory,
                        occupation : this.viewer.usersOccupation,
                        city : this.viewer.usersCity,
                        cp : this.viewer.usersPostalCode,
                        country : this.country,
                        usersPreferredLocale : this.preferredLocale,
                        usersAutomaticallySubscribeToDocuments : this.viewer.usersAutomaticallySubscribeToDocuments
                    }
                }).then((result) => {
                    // Flash the success
                    this.$flashStorage.destroyAll();
                    this.flash(this.$t('flash.manage-profile-success'), 'success', { timeout : this.flashSuccessTimeout});
                    this.updateIsLoading(false);

                    // Try to save activation key
                    const setActivationKeyResult = this.$apollo.mutate({
                        mutation : gql`mutation($userId : Int!, $activationKey : String!){
                            setUserActivationKey(userId: $userId, activationKey: $activationKey)
                        }`,
                        variables : {
                            userId : parseInt(this.user.item.id),
                            activationKey : this.viewer.usersActivationKey[0].title
                        }
                    }).then((result) => {
                        // Flash the error
                        this.flash(this.$t('flash.activation-key-success'), 'success', { timeout : this.flashSuccessTimeout});

                        // Update loading state
                        this.updateIsLoading(false);
                    }).catch(() => {
                        // Flash the error
                        this.flash(this.$t('flash.activation-key-fail'), 'error', { timeout : this.flashErrorTimeout});
                    });
                }).catch((error) => {
                    // Flash the error
                    this.flash(this.$t('flash.manage-profile-error'), 'error', { timeout : this.flashErrorTimeout});

                    // Update loading state
                    this.updateIsLoading(false);

                    // Log the error
                    console.log('error manage profile', error);
                })
            },

            usersAutomaticallySubscribeToDocumentsChecked(pState){
                this.viewer.usersAutomaticallySubscribeToDocuments = pState;
            },

            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb', 'updateIsLoading'] )
        },

        apollo : {
            viewer : {
                query : gql`
                    query Viewer {
                    viewer : lhsViewer {
                        email
                        firstName
                        lastName
                        ...on User {
                            usersCivility
                            usersInstitution
                            usersLaboratory
                            usersOccupation
                            usersCity
                            usersPostalCode
                            usersAutomaticallySubscribeToDocuments
                            usersCountry {
                                iso2
                            }
                            usersPreferredLocale {
                                handle
                            }
                            usersActivationKey {
                                title
                            }
                        }
                      }
                    }
                `,

                result(result){
                    // Update loading state
                    this.updateIsLoading(false);

                    this.country = result.data.viewer.usersCountry.iso2;
                    this.preferredLocale = result.data.viewer.usersPreferredLocale.handle;
                }
            }
        }
    }
</script>

<style lang="scss">
    .user-profil-container {
        @apply box-border;
        padding-top: 29px;
        padding-bottom: 50px;

        @screen md {
            max-width: 696px;
        }

        .legend {
            margin: 0 24px 25px 24px;
        }

        .field {
            @apply flex items-center w-full border-t border-solid border-trace-greyslight2;
            height: 51px;
            padding: 0 24px;

            label {
                width: 220px;
                @apply text-text-greystrong;
            }

            .value {
                @apply font-arial text-sm text-text-greylight2;
            }

            .non-editable {
                @apply justify-self-end font-arial text-xs text-text-greylight;
            }

            input {
                margin: 5px -4px;
            }
            .dropdown {
                margin: 5px -4px;
            }
            &.last {
                @apply border-b border-solid border-trace-greyslight2;
            }
            select {
                width: 240px;
                height: 40px;
                margin: 5px -4px;
            }
            .keyIsLoading {
                margin-left: 10px;
                width: 40px;
                height: 51px;
                line-height: 51px;
            }
        }
        .language-notif {
            margin: 30px 24px 40px 24px;
            p {
                margin: 0;
            }
            .dropdown {
                margin-top: 15px;
            }
        }
        .user-profile-submit {
            text-align: center;
            .submit {
                display: inline;
            }
        }
        .notifSelect {
            margin-top: 15px;
            width: 240px;
            height: 40px;
        }
    }
</style>
