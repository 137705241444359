<template>
    <div class="home-card category-card">
        <router-link v-if="card.type === 'entry' || card.type === undefined" class="card"
             :to="card.element.uri"
        >
            <card-content :card="card.element" :image="image" :unread-document-count="unreadDocumentCount"></card-content>
        </router-link>
        <a v-if="card.type === 'url'" :href="card.url" :target="card.target" class="card">
            <card-content :card="(card.element) ? card.element : card" :image="image"></card-content>
        </a>
    </div>
</template>

<script>
import CardContent from "./CardContent";

export default {
    name : 'home-card',

    components : { CardContent },

    props : {
        card: { type: Object, default: () => {} },
        image: { type: Array, default: () => [] },
        unreadDocumentCount: { type: Number, default: 0 },
    }
}
</script>
