<template>
    <section class="document-category w-full">
        <div class="document-category__content">
            <!-- IF CATEGORY -->
            <div v-if="category" class="flex justify-center flex-wrap">
                <div class="category-description-container flex flex-row justify-start md:justify-between">
                    <router-link
                       :to="routeBack"
                       class="back-button-container"
                    >
                        <div class="btn-rond" >
                            <img src="~@/assets/images/common/back-button.png" />
                        </div>
                        <h5 class="back-title h5">{{ $t('documents.back') }}</h5>
                    </router-link>
                    <div class="description-container">
                        <h1 class="title h2">{{ category.title }}</h1>
                    </div>
                    <div v-if="category.documentCategoriesImage.length > 0" class="image-container" :class="{ 'image-container--card-header-cover': category.documentShowImageAsCardHeaderCover }">
                        <img :src="category.documentCategoriesImage[0].url" />
                    </div>
                </div>
            </div>

            <div class="document-select-bar">
                <div class="left">
                    <select ref="typeSelect" class="document-select-bar__select" @change="filterTypes">
                        <option value="" selected disabled>{{ $t('documents.filters.dropdownLabel') }}</option>
                        <option value="">{{ $t('documents.filters.all') }}</option>
                        <template v-for="(type, index) in documentsByType">
                            <option v-if="type.documents.length > 0" :key="'select-option-'+index" :value="'type-'+type.id">{{ type.title }}</option>
                        </template>
                    </select>
                </div>
                <div class="right">
                    <span class="selectionButton">
                        <a @click.prevent="selectAll()" class="selectAllButon flex items-center md:ml-4">
                            <div class="btn-nav btn-check"></div>
                            <div class="btn-label">{{ $t('documents.filters.select-all') }}</div>
                        </a>
                        <a @click.prevent="unselectAll()" class="deselectAllButton flex items-center md:ml-4">
                            <div class="btn-nav btn-croix"></div>
                            <div class="btn-label">{{ $t('documents.filters.deselect-all') }}</div>
                        </a>
                    </span>
                    <a
                        v-if="documentSelection.length > 0"
                        :href="multipleDownloadUrl"
                        class="downloadSelectionButton flex items-center md:ml-4"
                    >
                        <div class="btn-nav btn-dl"></div>
                        <div class="btn-label">{{ $t('documents.filters.download-selection') }}</div>
                    </a>
                </div>
            </div>

            <template v-for="(type, index) in documentsByType">
                <documents-group
                    ref="documentsGroup"
                    v-if="type.documents.length > 0"
                    :id="'type-'+type.id"
                    :key="'type'+index"
                    :group-id="type.id"
                    :group-name="type.title"
                    :documents="type.documents"
                    class="documents-group"
                ></documents-group>
            </template>
        </div>
    </section>
</template>

<script>
    import { gsap }                   from 'gsap';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import gql                        from 'graphql-tag';
    import DocumentsGroup             from '../../components/DocumentsGroup';

    export default {
        name : 'document-type',

        components : { DocumentsGroup },

        data(){
            return {
                category:        null,
                type:            null,
                documentsByType: [],
                breadcrumbArray: []
            }
        },

        computed : {
            uri(){
                let slug = (this.$route.params.slug) ? this.$route.params.slug : '';
                let slug1 = (this.$route.params.slug1) ? '/' + this.$route.params.slug1 : '';
                let slug2 = (this.$route.params.slug2) ? '/' + this.$route.params.slug2 : '';
                return 'category/'+ slug  + slug1 +  slug2
            },

            multipleDownloadUrl() {
                let docPart = '';
                this.documentSelection.forEach((pId) => {
                    docPart += '&documentIds[]='+ pId
                });
                return '/'+ this.$route.params.lang + '/documents/download-archive?jwt='+ this.user.jwt + docPart
            },

            canDocumentsBeLoaded() {
                return this.category && this.type;
            },

            routeBack(){
                return (this.breadcrumb && this.breadcrumb.length > 0) ? this.breadcrumb[this.breadcrumb.length -2].url : {};
            },

            ...mapState(['documentSelection', 'user', 'breadcrumb']),
            ...mapState('subscriptions', ['subscriptions']),
            ...mapGetters('subscriptions', ['isSubscribed'])
        },

        watch : {
            category() {
                this.buildBreadcrumb();
            },

            type() {
                this.buildBreadcrumb();
            },

            subscriptions() {
                this.buildBreadcrumb();
            },

            async canDocumentsBeLoaded(newValue) {
                if (!newValue) {
                    return;
                }

                const promises = this.type.children.concat([this.type]).map(item => this.$apollo.query({
                    query : gql`
                        query Documents($catId: Int, $typeId: Int) {
                              documents(category: $catId, type: $typeId){
                                  id
                                  isNew
                                  viewUrl
                                  title
                                  dateUpdated
                                  ... on documents_cta_Document {
                                    documentsCta
                                    documentsDescription
                                  }
                                  ... on documents_file_Document {
                                    documentsDescription
                                    downloadUrl
                                    documentsImage {
                                        url
                                      }
                                  }
                              }
                        }
                    `,

                    fetchPolicy: 'no-cache',

                    variables: {
                        catId: parseInt(this.category.id, 10),
                        typeId : parseInt(item.id, 10),
                    },
                }).then(result => {
                    return {
                        id: item.id,
                        title: item.title,
                        documents: result.data.documents,
                    }
                }));

                /** @var {Array} result */
                const result = await Promise.allSettled(promises);
                this.documentsByType = result
                    .filter(item => item.status === 'fulfilled') // Remove errors from results
                    .map(item => item.value);
                this.updateIsLoading(false);
            },
        },

        mounted(){
            // Update loading state
            this.updateIsLoading(true);

            this.updateMainBackgroundState(2);

            // Show breadcrumb
            this.updateBreadcrumbState(true);
        },

        methods : {
            buildBreadcrumb(){
                if(this.type && this.category && this.subscriptions) {
                    // Update the breadcrumb
                    this.updateBreadcrumb([]);

                    this.breadcrumbArray = [
                        {
                            url : { name : 'home', params : { lang : this.$i18n.locale}},
                            label : 'breadcrumb.home',
                            i18n: true
                        }
                    ];

                    // If the request isn't empty
                    if(this.category && this.category.ancestors.length > 0) {
                        // Go through ancestors
                        this.category.ancestors.forEach((pAncestor) => {
                            let breaditem = {
                                url : { name : 'category', params : { lang : this.$i18n.locale, slug : pAncestor.slug}},
                                label : pAncestor.title,
                                isSubscribed: this.isSubscribed({
                                    type: 'documentCategory',
                                    documentCategoryId:  pAncestor.id
                                })
                            };

                            if(this.breadcrumbArray.indexOf(breaditem) === -1) {
                                // Add to the breadcrumb array
                                this.breadcrumbArray.push(breaditem);
                            }
                        });
                    }

                    // Breadcrumb object for this page
                    let thiscategory = {
                        url : { name : 'category', params : {lang : this.$i18n.locale, slug : this.category.slug}},
                        label : this.category.title,
                        isSubscribed: this.isSubscribed({
                            type: 'documentCategory',
                            documentCategoryId:  this.category.id
                        })
                    };

                    // If it isn't already in the breadcrumb
                    if(this.breadcrumbArray.indexOf(thiscategory) === -1) {
                        // add it
                        this.breadcrumbArray.push(thiscategory);
                    }

                    let thistype = {
                        url : null,
                        label : this.type.title,
                        isSubscribed: this.isSubscribed({
                            type: 'documentType',
                            documentTypeId: this.type.id,
                            documentCategoryId: this.category.id
                        })
                    };

                    // If it isn't already in the breadcrumb
                    if(this.breadcrumbArray.indexOf(thistype) === -1) {
                        // add it
                        this.breadcrumbArray.push(thistype);
                    }

                    // Update the breadcrumb
                    this.updateBreadcrumb(this.breadcrumbArray);
                }
            },


            // Select all documents
            selectAll() {
                // Go through all types
                this.documentsByType.forEach((pType, typeIndex) => {
                    // Go through all doc in the type
                    pType.documents.forEach((pDoc, docIndex) => {
                        if(!pDoc.documentsCta) {
                            // Add the id to the selection
                            this.pushDocumentSelection(pDoc.id);
                        }
                    });
                });
            },

            // Unselect all documents
            unselectAll(){
                // Empty selection array
                this.updateDocumentSelection([]);
            },

            filterTypes(){
                // console.log(this.$refs.typeSelect.value, this.$refs.documentsGroup, document.querySelector('#'+this.$refs.typeSelect.value));
                let selectValue = this.$refs.typeSelect.value;

                if(selectValue !== '') {
                    // Hide the documents group
                    gsap.set(document.querySelectorAll('.documents-group'), {
                        display : 'none',
                        duration : 0.5,
                        ease : 'power3.out'
                    });

                    // Show the only type
                    gsap.set(document.querySelector('#'+this.$refs.typeSelect.value), {
                        display : 'block',
                        duration : 0.5,
                        ease : 'power3.out'
                    });
                }
                else {
                    // Show the documents group
                    gsap.set(document.querySelectorAll('.documents-group'), {
                        display : 'block',
                        duration : 0.5,
                        ease : 'power3.out'
                    });

                }
            },

            ...mapMutations(['pushDocumentSelection', 'updateDocumentSelection', 'updateIsLoading', 'updateMainBackgroundState', 'updateBreadcrumb', 'updateBreadcrumbState'])
        },

        apollo: {
            category: {
                query: gql`query category($slug: [String]) {
                    category: entry(slug: $slug, section: "documentCategories") {
                        id
                        title
                        slug
                        ... on documentCategories_documentCategories_Entry {
                            documentShowImageAsCardHeaderCover
                            documentCategoriesImage {
                                url
                            }
                        }
                        ancestors {
                            id
                            title
                            slug
                        }
                    }
                }`,
                variables(){
                    return {
                        slug : this.$route.params.slug
                    }
                },
                result(pResult){
                    if(!pResult.data.category) {
                        this.updateIsLoading(false);
                    }
                }
            },
            type: {
                query: gql`query type($slug: [String]) {
                    type: entry(slug: $slug, section: "documentTypes") {
                        id
                        slug
                        title
                        children {
                            id
                            slug
                            title
                        }
                    }
                }`,
                variables(){
                    return {
                        slug : this.$route.params.slug2
                    }
                },
                result(pResult){
                    if(!pResult.data.type) {
                        this.updateIsLoading(false);
                    }
                }
            },
        }
    }
</script>

<style lang="scss">
    .document-category {

        &__content {
            @apply w-full;
            max-width: 1310px;
            padding : 40px 16px 0 16px;

            @screen md {
                padding: 40px 40px 0 40px;
            }
        }
    }
</style>
