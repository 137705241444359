<template>
    <section class="home w-full flex flex-col justify-start items-start">
        <div class="home__content flex justify-center lg:justify-start items-start flex-wrap">
            <template v-if="content && content.length > 0 && !isLoading">
                <home-card
                    v-for="(item, index) in content"
                    :key="'card'+index"
                    :card="item.cta"
                    :image="item.image"
                    :unread-document-count="item.unreadDocumentCount"
                    class=""
                >
                </home-card>
            </template>
        </div>
        <template v-if="!isLoading && content && content.length === 0">
            <div class="flex flex-col items-center justify-center w-full h-full -mt-10">
                <h2 class="text-center">{{ $t('documents.home-no-content')}}</h2>
                <router-link class="btn btn--orange mt-4" :to="{ name : 'user-profile'}">{{ $t('documents.home-no-content-button') }}</router-link>
            </div>
        </template>
    </section>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import gql from "graphql-tag";
    import HomeCard from "../../components/HomeCard";

    export default {
        name : 'home',

        components: { HomeCard },

        data() {
            return {
                homeEntries: [],
                allowedCategoriesAndTypes: [],
                unreadDocumentCounts: [],
            }
        },

        computed: {
            content (){
                if(this.homeEntries.length === 0) {
                    return;
                }

                const cards = [];
                this.homeEntries.forEach(entry => {
                    // URL link type
                    if (entry.cta.type !== 'entry') {
                        cards.push(entry);
                        return;
                    }

                    // Element link type
                    if (entry.cta.element) {
                        // CMS page
                        if (![
                            'documentCategories_documentCategories_Entry',
                            'documentTypes_documentTypes_Entry',
                        ].includes(entry.cta.element.__typename)) {
                            cards.push(entry);
                            return;
                        }

                        // Document category or type
                        if (this.allowedCategoriesAndTypes.find(allowedEntry => allowedEntry.id === entry.cta.element.id)) {
                            entry.unreadDocumentCount = this.unreadDocumentCounts[entry.cta.element.id];
                            cards.push(entry);
                        }

                        // FIXME: handle types
                    }
                });

                return cards;
            },

            contentReceived() {
                return this.homeEntries.length > 0 && this.allowedCategoriesAndTypes.length > 0;
            },

            ...mapState(['isLoading'])
        },

        watch: {
            contentReceived() {
                if (this.contentReceived) {
                    this.updateIsLoading(false)
                }
            }
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(2);

            // Show breadcrumb
            this.updateBreadcrumbState(true);

            // Update breadcrumb content
            this.updateBreadcrumb([
                {
                    url : null,
                    label : 'breadcrumb.home',
                    i18n: true
                }
            ]);

            this.updateIsLoading(true);
        },

        methods : {
            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb', 'updateIsLoading'])
        },

        apollo : {
            homeEntries: {
                query: gql`query {
                    globalSet(handle: "home") {
                        ...on home_GlobalSet{
                            globalSetsHomeEntries{
                                ...on globalSetsHomeEntries_cta_BlockType{
                                    id
                                    image {
                                        url
                                    }
                                    cta{
                                        type
                                        url
                                        text
                                        ariaLabel
                                        target
                                        element {
                                            id
                                            title
                                            slug
                                            uri
                                            __typename
                                            ... on documentCategories_documentCategories_Entry {
                                                documentShowImageAsCardHeaderCover
                                                documentCategoriesImage {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`,
                // Only keep the interesting part of the response in the `homeEntries` property
                update: data => data.globalSet.globalSetsHomeEntries,
            },

            allowedCategoriesAndTypes: {
                // Do not run this query until the `homeEntries` query has returned
                skip() {
                    return this.homeEntries.length < 1;
                },
                query: gql`query allowedCategories($ids: [QueryArgument]) {
                    allowedCategories: documentCategories(id: $ids) {
                        category {
                            id
                            title
                        }
                        unreadDocumentCount
                    }

                    allowedTypes: documentTypes {
                        type {
                            id
                            title
                        }
                        unreadDocumentCount
                    }
                }`,
                fetchPolicy: 'no-cache',
                update(data) {
                    this.unreadDocumentCounts = Object.fromEntries([
                        ...data.allowedCategories.map(item => [ item.category.id, item.unreadDocumentCount ]),
                        ...data.allowedTypes.map(item => [ item.type.id, item.unreadDocumentCount ]),
                    ]);

                    return [].concat(
                        data.allowedCategories.map(item => item.category),
                        data.allowedTypes.map(item => item.type)
                    );
                },
                variables() {
                    return {
                        ids: this.homeEntries
                                 .filter(item => item.cta && item.cta.type === 'entry' && item.cta.element)
                                 .map(item => item.cta.element.id),
                    }
                },
            },
        }
    }
</script>

<style lang="scss">
    .home {
        &__content {
            @apply py-6 px-4 mx-auto;
            max-width: 1310px;

            @screen md {
                @apply px-10;
            }
        }
    }
</style>
