import Vue             from 'vue';
import VueI18n         from 'vue-i18n';
import dateTimeFormats from '@/locales/date-time-formats';

Vue.use(VueI18n);


const i18n = new VueI18n({
    locale: document.documentElement.lang.toLowerCase(),
    fallbackLocale: 'en-gb',
    messages: {},
    dateTimeFormats,
});

(async () => {
    const response = await fetch(document.getElementById('i18n-messages').href);
    const messages = await response.json();
    i18n.setLocaleMessage(i18n.locale, messages);
})();

export default i18n;

