<template>
    <main class="app relative flex flex-col min-h-screen">
        <main-background/>
        <header-custom/>
        <breadcrumb/>

        <div class="relative flex-grow flex w-full overflow-hidden">
            {{ errorCode }}
            {{ errorMessage }}
            <router-view/>
        </div>

        <footer-custom/>

        <loader></loader>
        <flash-message class="flash-message"></flash-message>
    </main>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import HeaderCustom from "./components/HeaderCustom";
import FooterCustom from "./components/FooterCustom";
import MainBackground from "./components/MainBackground";
import Breadcrumb from "./components/Breadcrumb";
import Loader from "./components/Loader";
import gql from "graphql-tag";

export default {
        name : 'App',

        components: {Breadcrumb, MainBackground, HeaderCustom, FooterCustom, Loader },

        props: {
            errorCode: { type: Number, default: null },
            errorMessage: { type: String, default: null },
        },

        computed: {
            ...mapState(['isAuthenticated'])
        },

        watch : {
            isAuthenticated(){
                this.fetchSubscriptions();
            }
        },

        mounted(){
            if(!JSON.parse(localStorage.getItem('user'))) {
                // console.log('trace : session doesnt exist');

                this.updateIsAuthenticated(false);

                return false;
            }

            let user = JSON.parse(localStorage.getItem('user'));

            // If it is expired
            if(Date.now() >= user.jwtExpiresAt) {
                // console.log('trace : session is expired');
                localStorage.removeItem('user');

                this.updateIsAuthenticated(false);

                this.$router.push({ name : 'login', params : { lang : this.$i18n.locale }});

                return false;
            }

            // console.log('trace : session is still available');
            // Set user in store
            this.updateUser(user);

            // Say we are auth
            this.updateIsAuthenticated(true);

            this.$store.dispatch('refreshToken');
        },

        onActive() {
            let user = JSON.parse(localStorage.getItem('user'));

            // If it is expired
            if(user && Date.now() >= user.jwtExpiresAt) {
                // Reset session
                localStorage.removeItem('user');

                // Reset user in store
                this.updateUser({});

                // Not auth anymore
                this.updateIsAuthenticated(false);

                // Go to login
                this.$router.push({ name : 'login', params : { lang : this.$i18n.locale }});

                // Flash the error
                this.flash(this.$t('flash.logged-out'), 'error', { timeout : this.flashErrorTimeout});

                this.updateIsLoading(false);
            }
        },

        methods : {
            ...mapMutations(['updateUser', 'updateIsAuthenticated', 'updateLangs', 'updateCountries']),
            ...mapMutations('subscriptions', ['setSubscriptions']),
            ...mapActions('subscriptions', ['fetchSubscriptions']),
        },

        apollo : {
            countries : {
                query : gql`
                    query {
                        countries {
                            name
                            nativeName
                            iso2
                        }
                    }
                `,

                result(result){
                    // Store the countries
                    this.updateCountries(result.data.countries);
                }
            },

            langs : {
                query : gql`
                    query {
                      langs: globalSets(handle: "enabledLocales") {
                        ...on enabledLocales_GlobalSet {
                          globalSetsLocales {
                            ...on globalSetsLocales_locales_BlockType {
                              enabledLocale {
                                name
                                handle
                                language
                                baseUrl
                              }
                              label
                            }
                          }
                        }
                      }
                    }
                `,

                result(result){
                    if (!result.data.langs || !result.data.langs[0].globalSetsLocales) {
                        console.error('Unexpected langs query result:', result.data);
                        return;
                    }
                    const locales = result.data.langs[0].globalSetsLocales.map(locale => ({
                        code: locale.enabledLocale.handle,
                        label: locale.label ? locale.label : locale.enabledLocale.name,
                        slug: locale.enabledLocale.language.toLowerCase(),
                        baseUrl: locale.enabledLocale.baseUrl,
                    }));

                    // If we have langs
                    if(this.langs.length > 0) {
                        // Try to get the current langauge
                        const currentLanguage = locales.find((o) => {
                            return o.slug.toLowerCase() == this.$route.params.lang
                        });

                        // If we have none  (wrong language code)
                        if (!currentLanguage) {
                            // Redirect to the home
                            window.location =  '/en-gb';
                        }
                    }

                    // Store the new array
                    this.updateLangs(locales);
                }
            }
        }
    }
</script>

<style lang="scss">
    .flash-message {
        @apply fixed;
        top : 150px;
        right : 10px;
    }
</style>
