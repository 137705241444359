import {apolloClient} from '@/app/apollo';
import gql from 'graphql-tag';

export const TYPE_DOCUMENT = 'document';
export const TYPE_DOCUMENT_CATEGORY = 'documentCategory';
export const TYPE_DOCUMENT_TYPE = 'documentType';

export default {
    namespaced: true,
    state: () => ({
        subscriptions : [],
    }),
    mutations: {
        /**
         * Replaces existing subscriptions with those passed as the `subscriptions` parameter
         * @param state
         * @param {Array} subscriptions
         */
        setSubscriptions(state, subscriptions) {
            state.subscriptions = subscriptions;
        },

        /**
         * Adds a subscription
         * @param state
         * @param subscription
         */
        addSubscription(state, subscription) {
            state.subscriptions.push(subscription);
        },

        /**
         * Removes an existing subscription
         * @param state
         * @param subscription
         */
        removeSubscription(state, subscription) {
            const index = state.subscriptions.findIndex(existingSubscription => isSameSubscription(existingSubscription, subscription));
            if (index === -1) {
                return;
            }

            state.subscriptions.splice(index, 1);
        },
    },

    actions: {
        /**
         * Toggles a subscription: remove if it exists, add it otherwise.
         * @param commit
         * @param state
         * @param getters
         * @param subscription
         */
        toggleSubscription ({commit, state, getters}, subscription) {
            if (getters.isSubscribed(subscription)) {
                commit('removeSubscription', subscription);
                return;
            }

            commit('addSubscription', subscription);
        },

        /**
         * Get subscriptions for the current site
         * @param commit
         * @returns {Promise<void>}
         */
        async fetchSubscriptions({ commit }) {
            const results = await apolloClient.query({
                query: gql`
                        query subs($site: [String]) {
                            subscriptions(site: $site) {
                                ...on document_Subscription {
                                    documentId
                                }
                                ... on documentCategory_Subscription {
                                    documentCategoryId
                                }
                                ... on documentType_Subscription {
                                    documentCategoryId
                                    documentTypeId
                                }
                            }
                        }
                    `,
                fetchPolicy: 'no-cache',
                variables: {
                    site: document.documentElement.lang.replace('-', '')
                },
            });

            const subscriptions = results.data.subscriptions.map(item => {
                const subscription = {};
                switch(item.__typename) {
                    case 'document_Subscription':
                        subscription.type = TYPE_DOCUMENT;
                        subscription.documentId = item.documentId;
                        break;
                    case 'documentCategory_Subscription':
                        subscription.type = TYPE_DOCUMENT_CATEGORY;
                        subscription.documentCategoryId = item.documentCategoryId;
                        break;
                    case 'documentType_Subscription':
                        subscription.type = TYPE_DOCUMENT_TYPE;
                        subscription.documentCategoryId = item.documentCategoryId;
                        subscription.documentTypeId = item.documentTypeId;
                        break;
                }
                return subscription;
            });

            commit('setSubscriptions', subscriptions);
        },

        async saveSubscriptions({ state }) {
            const results = await apolloClient.mutate({
                mutation: gql`
                    mutation SaveSubscriptions($subs: [SubscriptionInput]) {
                        replaceSubscriptionsForCurrentSite(
                            subscriptions: $subs
                        )
                    }
                `,
                variables: {
                    subs: state.subscriptions.map(subscription => {
                        const subscriptionMutationInput = {};
                        switch (subscription.type) {
                            case TYPE_DOCUMENT:
                                subscriptionMutationInput.documentId = parseInt(subscription.documentId, 10);
                                break;
                            case TYPE_DOCUMENT_CATEGORY:
                                subscriptionMutationInput.documentCategoryId = parseInt(subscription.documentCategoryId, 10);
                                break;
                            case TYPE_DOCUMENT_TYPE:
                                subscriptionMutationInput.documentCategoryId = parseInt(subscription.documentCategoryId, 10);
                                subscriptionMutationInput.documentTypeId = parseInt(subscription.documentTypeId, 10);
                                break;
                        }

                        return subscriptionMutationInput;
                    }),
                }
            });
        }
    },

    getters: {
        /**
         * Return whether the given `candidateSubscription` is already subscribed or not
         * @param state
         * @returns {function(object): boolean}
         */
        isSubscribed: (state) => (candidateSubscription) => {
            return undefined !== state.subscriptions.find(existingSubscription => isSameSubscription(existingSubscription, candidateSubscription));
        },
    },
};

/**
 * Validates a subscription object and ensures it has the following properties: type, documentId, documentCategoryId, documentTypeId
 * @param subscription
 * @returns {{documentCategoryId, documentTypeId, documentId, type}}
 */
function normalizeSubscription(subscription) {
    if (![TYPE_DOCUMENT, TYPE_DOCUMENT_CATEGORY, TYPE_DOCUMENT_TYPE].includes(subscription.type)) {
        console.error(subscription);
        throw new Error(`Subscription has an invalid type: ${subscription.type}`);
    }

    if (subscription.type === TYPE_DOCUMENT && !subscription.documentId) {
        console.error(subscription);
        throw new Error('Document subscription has no documentId');
    }

    if (subscription.type === TYPE_DOCUMENT_CATEGORY && !subscription.documentCategoryId) {
        console.error(subscription);
        throw new Error('Document category subscription has no documentCategoryId');
    }

    if (subscription.type === TYPE_DOCUMENT_TYPE) {
        if (!subscription.documentTypeId) {
            console.error(subscription);
            throw new Error('Document type subscription has no documentTypeId');
        }

        if (!subscription.documentCategoryId) {
            console.error(subscription);
            throw new Error('Document type subscription has no documentCategoryId');
        }
    }

    return {
        type: subscription.type,
        documentId: subscription.type === TYPE_DOCUMENT ? subscription.documentId : undefined,
        documentCategoryId: [TYPE_DOCUMENT_CATEGORY, TYPE_DOCUMENT_TYPE].includes(subscription.type) ? subscription.documentCategoryId : undefined,
        documentTypeId: subscription.type === TYPE_DOCUMENT_TYPE ? subscription.documentTypeId : undefined,
    };
}

/**
 * Return whether subscriptions are identical or not
 * @param subscription1
 * @param subscription2
 * @returns {boolean}
 */
function isSameSubscription(subscription1, subscription2) {
    subscription1 = normalizeSubscription(subscription1);
    subscription2 = normalizeSubscription(subscription2);

    // noinspection EqualityComparisonWithCoercionJS
    return subscription2.type == subscription1.type
        && subscription2.documentId == subscription1.documentId
        && subscription2.documentCategoryId == subscription1.documentCategoryId
        && subscription2.documentTypeId == subscription1.documentTypeId
}
