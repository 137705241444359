<template>
    <div class="preinscription w-full overflow-hidden">
        <div class="w-full mt-16 box-border">
            <div class="preinscription__content bg-greylight3 rounded-md mx-auto pt-6 pb-12 px-6">
                <div class="text-center">
                    <h2 class="text-text-greystrong">{{ $t('preinscription.heading')}}</h2>
                    <p class="mt-4" v-html="$t('preinscription.text')"></p>
                    <a href="#" class="btn btn--orange mt-6" @click.prevent="resendEmail">{{ $t('preinscription.cta') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag";
    import { mapState, mapMutations } from 'vuex';

    export default {
        name : 'preinscription',

        data(){
            return {
                email : ''
            }
        },

        computed : {
            ...mapState(['flashErrorTimeout', 'flashSuccessTimeout'])
        },

        mounted(){
            // Get the search term
            this.email = this.$route.query.email;

            this.updateMainBackgroundState(1);
        },

        methods : {
            resendEmail(){
                this.$apollo.mutate({
                    mutation : gql`mutation($email : String!) {
                        resendActivation(email: $email)
                    }`,
                    variables : {
                        email : this.email
                    }
                }).then((result) => {
                    // Flash the success
                    this.$flashStorage.destroyAll();
                    this.flash(this.$t('flash.preinscription-success'), 'success', { timeout : this.flashSuccessTimeout});

                }).catch((error) => {
                    // Flash the error
                    this.flash(this.$t('flash.general-error'), 'error', { timeout : this.flashErrorTimeout});

                    // Error
                    console.error('error', error)
                });
            },

            ...mapMutations(['updateMainBackgroundState'])
        }
    }
</script>

<style lang="scss">
    .preinscription {
        &__content {
            max-width : 320px;

            @screen md {
                max-width : 520px;
            }
        }
    }
</style>
