<template>
    <div :class="{'pointer-events-none': checked === 0.5}" class="checkbox cursor-pointer flex items-center" @click="toggleCheck">
        <div class="checkbox__tick flex justify-center items-center bg-greylight2 border border-solid border-trace-greyslight2 rounded-sm">
            <span :class="[checkStyle, {'checked' : checked === 1, 'half-checked': checked === 0.5}]" class="checkbox__icon"></span>
        </div>
        <div class="checkbox__label">{{ label }}</div>
    </div>
</template>

<script>
    export default {
        name : 'checkbox',

        props : {
            // selected = 1 => checked, = 0.5 => half check, = 0 => unchecked
            selected : { type : Number, default : 0},
            label : { type : String, default : ''},
            checkStyle : { type : String, default : 'check'}
        },

        data(){
            return {
                checked : false
            }
        },

        watch : {
            selected(){
                this.checked = this.selected;
            }
        },

        mounted(){
            this.checked = this.selected;
        },

        methods : {
            toggleCheck(){
                // Toggle check
                this.checked = !this.checked;

                this.$emit('check-toggled', this.checked);
            }
        }
    }
</script>
