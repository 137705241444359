<template>
    <section v-if="content" class="content-page w-full">
        <template v-for="(block, index) in content.cmsBlocks">
            <div v-if="block.typeHandle === 'pageTitle'" :key="'cmstitle'+index" class="content-page__title mx-auto text-center my-6">
                <h2 class="text-white">{{ block.fields[0].heading }}</h2>
            </div>
            <div v-if="block.typeHandle === 'text'" :key="'cmstext'+index" class="content-page__text bd-block my-4">
                <div class="cms-content" v-html="block.fields[0].text"></div>
            </div>
        </template>
    </section>
</template>

<script>
    import { mapMutations } from 'vuex';
    import gql from "graphql-tag";

    export default {
        name : 'content-page',

        computed : {
            content(){
                if(!this.entry) {
                    return null;
                }

                return this.entry;
            }
        },

        watch : {
            entry(){
                // Update breadcrumb content
                this.updateBreadcrumb([
                    {
                        url : { name : 'prehome', params : { lang : this.$i18n.locale}},
                        label : 'breadcrumb.home',
                        i18n: true
                    }, {
                        url : null,
                        label : this.entry.title
                    }
                ]);
            }
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(1);

            // Show breadcrumb
            this.updateBreadcrumbState(true);


        },

        methods : {
            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb'])
        },

        apollo : {
            entry: {
                query : gql`
                    query($slug : [String]) {
                        entry(section: "contentPages", slug: $slug){
                            ... on contentPages_contentPages_Entry{
                                id
                                slug
                                title
                                cmsBlocks : contentBuildersMain {
                                    __typename

                                    ... on contentBuildersMain_pageTitle_BlockType {
                                      typeHandle
                                      fields: blockPageTitle {
                                        ... on blockPageTitle_BlockType {
                                          heading
                                        }
                                      }
                                    }
                                    ... on contentBuildersMain_text_BlockType {
                                    typeHandle
                                      fields: blockText{
                                        ... on blockText_BlockType {
                                          text
                                        }
                                      }
                                    }
                                }
                            }
                        }
                    }
                `,

                variables() {
                    return {
                        slug : this.$route.params.slug
                    }
                },
            }
        }
    }
</script>
