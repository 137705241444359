<template>
    <div class="w-full mt-16 mx-4 md:mx-0">
        <div class="forgot-dialog bg-greylight3 rounded-md mx-auto pt-6 pb-12 px-4">
            <div class="text-center">
                <h2 class="text-text-greystrong">{{ $t('login.forgot-password-question')}}</h2>
                <p class="mt-2">{{ $t('login.forgot-password-legend')}}</p>
            </div>

            <form
                name="forgotPassword"
                @submit.prevent="onSubmit"
                class="form-forgot mx-auto mt-6"
            >
                <input
                    type="text"
                    name="id"
                    :placeholder="$t('login.login-id')"
                    v-model="form.email"
                    @change="emailError = false"
                    required
                    class="input-text input-normal mb-3"
                />
                <p v-show="emailError === 'NO_ACCOUNT'" class="error-block">error-no-account-with-this-username</p>
                <p v-show="emailError === 'NOT_SEND'" class="error-block">error-occured-while-sending-email</p>
                <p v-show="emailSent" class="error-block">email-sent</p>

                <div class="mt-16 text-center flex items-center justify-center">
                    <router-link
                        :to="{ name : 'login', params : { lang : $route.params.lang}}"
                        class="text-text-grey text-xxs mr-3"
                    >
                        &lt; {{ $t('login.back') }}
                    </router-link>
                    <button
                        type="submit"
                        class="btn btn--orange"
                        :disabled="isInvalid || isLoading || emailSent"
                        :class="{
                            'is-loading': isLoading,
                            'is-valid': emailSent
                        }"
                    >
                        {{ $t('login.submit')}}
                    </button>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import gql from "graphql-tag";

    export default {
        name : 'forgot-password',

        data(){
            return {
                form : {
                    email : ''
                },
                emailError : '',
                isLoading : false,
                emailSent : false,
                isInvalid : false
            }
        },

        computed: {
            ...mapState(['flashErrorTimeout', 'flashSuccessTimeout'])
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(1);

            // Show breadcrumb
            this.updateBreadcrumbState(true);
        },

        methods : {
            onSubmit(){
                // Launch mutation
                this.$apollo.mutate({
                    mutation : gql`mutation($email : String!){
                        forgottenPassword(email: $email)
                    }`,
                    variables : {
                        email : this.form.email,
                    }
                }).then((result) => {
                    // Flash the success
                    this.$flashStorage.destroyAll();
                    this.flash(this.$t('flash.forgot-password-success'), 'success', { timeout : this.flashSuccessTimeout});

                }).catch((error) => {
                    // Flash the error
                    this.flash(this.$t('flash.forgot-password-error'), 'error', { timeout : this.flashErrorTimeout});

                    // Error
                    console.error('error', error)
                });
            },

            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState'])
        }
    }
</script>

<style lang="scss">
.forgot-dialog {
    max-width: 451px;
    height: 314px;

    .form-forgot{
        width: 240px;
    }

    .error-block {
        @apply absolute mt-0 text-xxs text-purple font-frutiger-regular;
    }
}
</style>
