import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory'
import {createApolloClient, restartWebsockets} from 'vue-cli-plugin-apollo/graphql-client'
import schema from './schema.json';

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint - Get it from the site's URL: use the first segment of the path if it looks like a language code
const httpEndpoint = `${document.location.origin}${getLangPrefix()}/api`;

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData : schema
})

const cache = new InMemoryCache({
    fragmentMatcher
});


// Config
const defaultOptions = {
    httpEndpoint,
    wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || null,
    tokenName: AUTH_TOKEN,
    persisting: false,
    websocketsOnly: false,
    ssr: false,
    cache
}

// Call this in the Vue app file
// Create apollo client
export const {apolloClient, wsClient} = createApolloClient({
    ...defaultOptions,
    getAuth : tokenName => {
        // console.log('tokenName', tokenName, JSON.parse(localStorage.getItem('user')));

        return (JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).jwt) ? 'JWT '+ JSON.parse(localStorage.getItem('user')).jwt : '';
    }
});
apolloClient.wsClient = wsClient

// Create vue apollo provider
const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
        $query: {
            // fetchPolicy: 'cache-and-network',
        },
    },
    errorHandler(error) {
        // eslint-disable-next-line no-console
        console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
})

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
    if (typeof localStorage !== 'undefined' && token) {
        localStorage.setItem(AUTH_TOKEN, token)
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(AUTH_TOKEN)
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    }
}

function getLangPrefix () {
    const pathName = document.location.pathname || ''
    const matches = pathName.match(new RegExp('^(/[a-z]{2}-[a-z]{2})/?'));
    if (!matches || matches.length < 2) {
        return '';
    }

    return matches[1];
}

export default apolloProvider;
