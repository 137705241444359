<template>
    <div class="card-content">
        <div :class="{
            'btn-rond' : !cardHeaderCover,
            'product-img' : cardHeaderCover
        }"
             class="overflow-hidden"
        >
            <img v-if="compImage" :src="compImage"/>
        </div>

        <div class="title h5">
            <span>{{ title }}</span>
        </div>

        <div v-if="unreadDocumentCount > 0" :class="{'meter--corner': cardHeaderCover}" class="meter">
            <span class="value">{{ unreadDocumentCount }}</span>
            <span v-if="!cardHeaderCover" class="label">{{ $tc('card.unread-document-count', unreadDocumentCount) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'card-content',

        computed : {
            title(){
                return (this.card.title) ? this.card.title : this.card.text
            },

            compImage(){
                if(this.card.element && this.card.element.documentCategoriesImage && this.card.element.documentCategoriesImage.length > 0) {
                    return this.card.element.documentCategoriesImage[0].url;
                }
                else if(this.card.element && this.card.element.documentTypesImage && this.card.element.documentTypesImage.length > 0) {
                    return this.card.element.documentTypesImage[0].url;
                }
                else if(this.card && this.card.documentCategoriesImage && this.card.documentCategoriesImage.length > 0) {
                    return this.card.documentCategoriesImage[0].url;
                }
                else if(this.card && this.card.documentTypesImage && this.card.documentTypesImage.length > 0) {
                    return this.card.documentTypesImage[0].url;
                }
                else if(this.image && this.image.length > 0) {
                    return this.image[0].url;
                }
                return false;
            },

            cardHeaderCover() {
                return (this.card && this.card.documentShowImageAsCardHeaderCover) ? this.card.documentShowImageAsCardHeaderCover : false;
            }
        },

        props : {
            card : { type : Object, default : () => {}},
            image : { type : Array , default : () => []},
            unreadDocumentCount: { type: Number, default: 0 },
        }
    }
</script>
