<template>
    <div></div>
</template>

<script>
    import { mapMutations } from 'vuex';

    export default {
        name : 'logout',

        created(){
            // Reset session
            localStorage.removeItem('user');

            // Reset user in store
            this.updateUser({});

            // Not auth anymore
            this.updateIsAuthenticated(false);

            // Go to login
            this.$router.push({ name : 'login', params : { lang : this.$i18n.locale }});
        },

        methods : {
            ...mapMutations(['updateUser', 'updateIsAuthenticated'])
        }
    }
</script>
