<template>
    <div class="pre-home basic-grid">
        <div class="pre-home__content flex flex-col justify-center h-full pb-8 md:pb-0">
            <h1 class="h1 text-white">{{ $t('prehome.home-title')}}</h1>
            <h5 v-if="$i18n.locale !== 'fr-fr'" class="h5 text-white mt-3">{{ $t('prehome.home-subtitle')}}</h5>

            <div v-if="$i18n.locale !== 'fr-fr'" class="flex flex-col md:flex-row items-start md:items-center mt-8 ">
                <div class="mr-6">
                    <router-link class="btn btn--ghost" :to="{name : 'create-account'}">
                        {{ $t('prehome.create-account')}}
                    </router-link>
                </div>
                <div class="bd-ds-video mt-4 md:mt-0">
                    <a class="btn btn--ghost btn-arrow-picto" @click.prevent="showVideo">
                        {{ $t('prehome.discover-in-video')}}
                    </a>
                </div>
            </div>

            <div v-if="$i18n.locale === 'fr-fr'" class="flex flex-col md:flex-row items-center mt-8">
                <div class="pre-home__block rounded">
                    <h4>{{ $t('prehome.home-preanalytique')}}</h4>
                    <p class="mt-2">{{ $t('prehome.home-preanalytique-description')}}</p>
                    <div class="mt-10 flex justify-between">
                        <a :href="$t('prehome.home-preanalytique-link')" class="btn btn--orange btn-siteAccess">{{ $t('prehome.home-site-access-button')}}</a>
                        <span class="sprite-logo-lab"></span>
                    </div>
                </div>

                <div class="pre-home__block rounded mt-4 md:ml-4 md:mt-0">
                    <h4>{{ $t('prehome.home-diagnostic')}}</h4>
                    <p class="mt-2">{{ $t('prehome.home-diagnostic-description')}}</p>
                    <div class="flex items-center mt-10">
                        <template v-if="isAuthenticated">
                            <router-link :to="{name : 'home', params : { lang : $route.params.lang}}" class="btn btn--orange btn-siteAccess">{{ $t('prehome.home-site-access-button')}}</router-link>
                        </template>
                        <template v-if="!isAuthenticated">
                            <router-link :to="{name : 'login', params : { lang : $route.params.lang}}" class="btn btn--orange btn-siteAccess">{{ $t('prehome.home-site-access-button')}}</router-link>
                        </template>
                        <a class="btn bd-ds-discover inline-flex items-center no-underline" @click.prevent="showVideo">
                            {{ $t('prehome.home-discover-button')}}
                            <span class="sprite-right-arrow-circle ml-1"></span>
                        </a>
                    </div>
                </div>
            </div>

            <div ref="videoContainer" class="pre-home__video-popin fixed w-screen h-screen px-16 py-8 inset-0" >
                <div ref="videoDimmer" class="pre-home__video-dimmer absolute inset-0 w-full h-full" @click="closeVideo"></div>
                <video
                    ref="video"
                    poster="~@/assets/videos/prehome/140506_bd_ds.jpg"
                    class="relative w-full h-full"
                    controls
                >
                    <source src="~@/assets/videos/prehome/140506_bd_ds.webm" type="video/webm" />
                    <source src="~@/assets/videos/prehome/140506_bd_ds.mp4" type="video/mp4"/>
                </video>
            </div>
        </div>

        <popin
            :close-button-enable="false"
            :title="$t('popin.restricted-access-title')"
            :text="$t('popin.restricted-access-text')"
            :button1="{label : $t('popin.yes'), action : 'close'}"
            :button2="{label : $t('popin.no'), action : 'redirect', url : $t('popin.no-redirection')}"
        />
    </div>
</template>

<script>
    import { gsap } from 'gsap';
    import { mapState, mapMutations } from 'vuex';
    import Popin from '../components/Popin';

    export default {
        name : 'pre-home',

        components : { Popin },

        computed : {
            ...mapState(['isAuthenticated'])
        },

        watch : {
            isAuthenticated(){
                // If we are already auth
                if(this.isAuthenticated) {
                    // Go to home
                    this.$router.push({ name : 'home', lang : this.$route.params.lang})
                }
            }
        },

        mounted(){
            // this.updateIsLoading(true);

            if(this.isAuthenticated) {
                // Go to home
                this.$router.push({ name : 'home', lang : this.$route.params.lang})
            }

            // Set the default background
            this.updateMainBackgroundState(0);

            // Don't show breadcrumb
            this.updateBreadcrumbState(false);
        },

        methods : {
            showVideo(){
                // Show the container
                gsap.to(this.$refs.videoContainer, {
                    y : '0%',
                    duration : 0.7,
                    ease : 'power3.out',
                    onComplete : () => {
                        this.$refs.video.play();
                    }
                });

                // Show the dimmer
                gsap.to(this.$refs.videoDimmer, {
                    opacity : 1,
                    duration : 0.3,
                    delay : 0.6,
                    ease : 'power3.out'
                });
            },

            closeVideo(){
                // Stop the video
                this.$refs.video.pause();

                // Hide the container
                gsap.to(this.$refs.videoContainer, {
                    y : '-100%',
                    duration : 0.7,
                    ease : 'power3.out',
                    onComplete : () => {
                    }
                });

                // Hide the dimmer
                gsap.to(this.$refs.videoDimmer, {
                    opacity : 0,
                    duration : 0.3,
                    ease : 'power3.out'
                });
            },

            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateIsLoading'])
        }
    }
</script>

<style lang="scss">
    .pre-home{
        &__content {
            max-width: 600px;
        }

        &__block {
            width : 290px;
            min-height : 242px;
            background-color : rgba(255,255,255,0.4);
            padding: 20px 18px;
        }

        &__carousel {
            bottom: 17%;
            right: 0;
        }

        &__video-popin {
            z-index : 52;
            transform : translateY(-100%);
        }

        &__video-dimmer {
            background-color : rgba(0,0,0,0.3);
            opacity : 0;
        }
    }
</style>
