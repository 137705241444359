<template>
    <div class="category-card">
        <router-link
            :to="{ name : type, params : { lang : $route.params.lang, slug : categorySlug ? categorySlug : card.slug, slug2 : card.slug}}"
            class="card"
        >
            <card-content :card="card" :unread-document-count="unreadDocumentCount"></card-content>
        </router-link>
    </div>
</template>

<script>
    import CardContent from "./CardContent";

    export default {
        name : 'category-card',

        components : { CardContent },

        props : {
            card : { type : Object, default : () => {}},
            type : { type : String, default : 'category'},
            categorySlug : { type : String, default : ''},
            unreadDocumentCount: { type: Number, default: 0 },
        }
    }
</script>
