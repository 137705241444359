<template>
    <user-template>
        <section class="user-password-change w-full my-6 md:my-10 bg-greylight3 mx-auto rounded-md">
            <p class="description">{{ $t('profile.change-password-legend') }}</p>

            <form @submit.prevent="updatePassword" class="mt-8">
                <div class="mb-8">
                    <label for="oldPassword">{{ $t('account.pwd-old') }}<sup>*</sup></label>
                    <input
                        type="password"
                        name="oldPassword"
                        v-model="form.oldPassword"
                        required
                        id="oldPassword"
                        class="input-text input-normal"
                    >
                </div>

                <div class="flex flex-col md:flex-row md:items-start mb-8">
                    <div class="field">
                        <label for="newPassword">{{ $t('account.pwd-new')}}<sup>*</sup></label>
                        <input
                            type="password"
                            name="newPassword"
                            :placeholder="$t('account.min-eight-char')"
                            v-model="form.newPassword"
                            minlength="8"
                            required
                            id="newPassword"
                            class="input-text input-normal"
                        >
                        <div v-if="form.newPassword.length > 0 && form.newPassword.length < 8" class="error-block mt-2">{{ $t('account.pwd-short')}}</div>
                    </div>

                    <div class="field mt-6 md:mt-0">
                        <label for="pwdConfirm">{{ $t('account.pwd-confirm') }}<sup>*</sup></label>
                        <input
                            type="password"
                            name="pwdConfirm"
                            :placeholder="$t('account.min-eight-char')"
                            v-model="form.pwdConfirm"
                            required
                            id="pwdConfirm"
                            class="input-text input-normal"
                        >
                        <div v-show="form.newPassword !== form.pwdConfirm"  class="error-block mt-2">{{ $t('account.pwd-match')}}</div>
                    </div>
                </div>


                <div class="change-pwd-submit flex justify-start items-center mt-2 text-center">
                    <button
                        type="submit"
                        :disabled="password.isSaving"
                        :class="{ 'is-loading': password.isSaving, 'is-valid': password.isSaved }"
                        class="btn btn--orange mr-3"
                    >
                        {{ $t('account.submit') }}
                    </button>
                    <span class="change-pwd-required font-arial text-xxs text-text-grey">{{ $t('account.required-cookies') }}</span>
                </div>
            </form>
        </section>
    </user-template>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import UserTemplate from "./UserTemplate";
    import gql from "graphql-tag";

    export default {
        name : 'user-password-change',

        components : {
            UserTemplate
        },

        data(){
            return {
                form : {
                    oldPassword : '',
                    newPassword : '',
                    pwdConfirm: ''
                },

                newPassword : '',
                pwdConfirm : '',
                password : {
                    isSaving : false,
                    isSaved : false
                }
            }
        },

        computed : {
            pwdMatch(){
                return this.form.newPassword === this.form.pwdConfirm;
            },

            pwdShort(){
                return this.form.newPassword.length < 8;
            },

            ...mapState(['flashErrorTimeout', 'flashSuccessTimeout'])
        },

        mounted(){
            // Set the default background
            this.updateMainBackgroundState(2);

            // Show breadcrumb
            this.updateBreadcrumbState(true);

            // Update breadcrumb content
            this.updateBreadcrumb([
                {
                    url : { name : 'home', params : { lang : this.$i18n.locale}},
                    label : 'breadcrumb.home',
                    i18n: true
                }, {
                    url : null,
                    label : 'profile.change-password',
                    i18n: true
                }
            ]);
        },

        methods : {
            updatePassword(){
                // Update loading state
                this.updateIsLoading(true);

                // Launch mutation
                this.$apollo.mutate({
                    mutation : gql`mutation UpdatePassword($currentPassword : String!, $newPassword : String!, $confirmPassword : String!){
                      updatePassword(
                        currentPassword: $currentPassword
                        newPassword: $newPassword
                        confirmPassword: $confirmPassword
                      )
                    }`,
                    variables : {
                        currentPassword: this.form.oldPassword,
                        newPassword: this.form.newPassword,
                        confirmPassword: this.form.pwdConfirm
                    }
                }).then((result) => {
                    // Flash the success
                    this.flash(this.$t('flash.change-password-success'), 'success', { timeout : this.flashSuccessTimeout});

                    // Update loading state
                    this.updateIsLoading(false);
                }).catch((error) => {
                    // Flash the success
                    this.flash(this.$t('flash.change-password-error'), 'error', { timeout : this.flashErrorTimeout});

                    // Update loading state
                    this.updateIsLoading(false);

                    // Log the error
                    console.log('Error change password', error);
                });
            },

            ...mapMutations(['updateMainBackgroundState', 'updateBreadcrumbState', 'updateBreadcrumb', 'updateIsLoading'])
        }
    }
</script>

<style lang="scss">
    .user-password-change {
        padding: 29px 24px 50px 24px;

        @screen md {
            max-width: 696px;
        }

        input {
            margin-right: 6px;
            margin-top: 5px;
        }
    }
</style>
